/** @param {jQuery} $ jQuery Object */
!function ($, window, document, _undefined)
{
    PortOneFive.ImageUploader = function ($uploader)
    {
        this.__construct($uploader)
    };

    PortOneFive.ImageUploader.prototype = {

        config: {
            action: '/uploads',
            multipleUploads: true,
            loadingImage: '/images/loading-bubbles.svg',
            onBeforeResize: function ()
            {
            }
        },

        __construct: function ($uploader)
        {
            this.$uploader = $uploader;

            this.$uploadControl = $('<div class="UploadControl"></div>').appendTo(this.$uploader);

            this.createUploadForm();

            this.config.multipleUploads = this.$fileInput.prop('multiple') !== 'undefined';

            this.$uploadControl.on('click', $.context(this, 'onClick'));
            this.$fileInput.on('change', $.context(this, 'beginUpload'));
        },

        createUploadForm: function ()
        {
            this.$uploadForm = $('<form method="post" enctype="multipart/form-data" style="visibility: hidden;" />');

            this.$fileInput = $('<input type="file" />');
            this.$uploadForm.attr('action', this.$uploader.dataOrDefault('action', this.config.action));

            this.$fileInput.attr('name', this.$uploader.dataOrDefault('name', 'upload'));

            var $uploadHash = $('<input type="hidden" name="upload_hash" />').val(this.$uploader.data('uploadHash'));

            this.$uploadForm.append($uploadHash).append(this.$fileInput).portOneFiveActivate().appendTo('body');

            return this.$uploadForm;
        },

        onClick: function (e)
        {
            this.$fileInput.trigger('click');
        },

        beginUpload: function (e)
        {
            var filename = this.$fileInput.val();

            if (!/\.(jpg|jpeg|gif|png)$/.test(filename.toLowerCase()))
            {
                PortOneFive.alert('Please upload a JPEG, GIF, or PNG file', 'Invalid Image');
                return false;
            }

            this.queuedFiles = e.target.files;

            this.$uploader.addClass('uploading').append(
                $('<div class="UploadingIndicator"><img src="' + this.config.loadingImage + '" /></div>')
            );

            var formData = new FormData(this.$uploadForm.get(0));

            var request = new PortOneFive.Ajax(
                '/uploads', {
                    processData: false,
                    contentType: false,
                    cache: false,
                    dataType: 'json'
                }
            );

            request.post(formData).done($.context(this, 'ajaxSaveResponse'));

            return false;
        },

        ajaxSaveResponse: function (ajaxData, textStatus)
        {
            this.$uploader.removeClass('uploading');

            this.$uploader.find('img').attr('src', '/uploads/' + ajaxData.data.upload.id);

            if (this.config && this.config.onBeforeResize)
            {
                var newE = $.Event();
                this.config.onBeforeResize(newE);
                if (newE.isDefaultPrevented())
                {
                    return;
                }
            }

            if (ajaxData.response.html)
            {
                var options = {
                    oneInstance: false,
                    noCache: true
                };

                this.$cropperOverlay = PortOneFive.createOverlay(this.$uploader, ajaxData.response.html, options);

                var self = this;

                this.$cropperOverlay.closed(
                    $.context(
                        function ()
                        {
                            var $image = self.$uploader.find('img');
                            $image.attr('src', $image.attr('src') + '?file=landscape');

                            this.$uploader.find('input[name="upload"]').val(1);
                        }, this
                    )
                );

                this.$cropperOverlay.open();

            }

            return false;
        },

        deCache: function ()
        {

        }
    };

    PortOneFive.ImageCropper = function ($cropper)
    {
        this.__construct($cropper);
    };

    PortOneFive.ImageCropper.prototype =
    {
        config: {
            aspectRatio: 16 / 9,
            autoCropArea: 0.80,
            strict: true,
            guides: true,
            highlight: false,
            background: false,
            dragCrop: false,
            movable: true,
            resizable: true,
            checkImageOrigin: false,
            responsive: false,
            modal: true,
            onClose: function ()
            {
                alert('okay');
            },
            crop: function (data)
            {
                var $fieldset = $(this).parent().find('fieldset');

                $fieldset.find('[data-x]').val(Math.round(data.x));
                $fieldset.find('[data-y]').val(Math.round(data.y));
                $fieldset.find('[data-width]').val(Math.round(data.width));
                $fieldset.find('[data-height]').val(Math.round(data.height));
            }
        },

        __construct: function ($cropper)
        {
            this.$cropper = $cropper;

            setTimeout(
                $.context(
                    function ()
                    {
                        this.$cropper.find('img').each(
                            $.context(
                                function (i, image)
                                {
                                    var $image = $(image);

                                    $image.cropper($.extend(true, {}, this.config, $image.data()));
                                }, this
                            )
                        );

                    }, this
                ), 200
            );
        },

        getCropData: function ()
        {
            return this.$cropper.cropper('getData');
        }
    };

    PortOneFive.register('.ImageUploader', 'PortOneFive.ImageUploader');
    PortOneFive.register('.ImageCropper', 'PortOneFive.ImageCropper');

}(jQuery, this, document);
