/** @param {jQuery} $ jQuery Object */
!function ($, window, document, _undefined) {

    PortOneFive.AddToFavoritesButton = function ($addToFavoritesButton) {
        $addToFavoritesButton.on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            var href;
            if ($addToFavoritesButton.hasClass('saved')) {
                href = $addToFavoritesButton.data('un-favorite-href');
            } else {
                href = $addToFavoritesButton.data('favorite-href');
            }

            var request = new PortOneFive.Ajax(href);

            request.get().done(function (ajaxData) {
                if (ajaxData.action == 'favorite') {
                    $addToFavoritesButton.addClass('saved');
                } else {
                    $addToFavoritesButton.removeClass('saved');
                }

                if ($addToFavoritesButton.closest('.UserFavorites').length == 1) {
                    $addToFavoritesButton.closest('.GridTile').portOneFiveRemove();
                }
            });
        });
    };

    PortOneFive.ItemSelector = function ($itemSelector) {
        $itemSelector.selectize({
            dataAttr: 'data-item',
            render: {
                option: function (item, escape) {
                    return (
                        '<div>' +
                        '<span class="item-title truncate">' + escape(item.title) + '</span>' +
                        '<span class="item-number">Item #: ' + escape(item.item_number) + '</span>' +
                        (
                            item.on_special
                                ? '<span class="label success" style="float: none; width: auto; margin: 0 10px; font-size:6px; position: relative;top: -2px;">ON SALE</span>'
                                : ''
                        ) +
                        (
                            item.discontinued
                                ? '<span class="label discontinued" style="float: none; width: auto; margin: 0 10px; font-size:6px; position: relative;top: -2px;">DISCONTINUED</span>'
                                : ''
                        ) +
                        (
                            item.out_of_stock
                                ? '<span class="label warning" style="float: none; width: auto; margin: 0 10px; font-size:6px; position: relative;top: -2px;">OUT OF STOCK</span>'
                                : ''
                        ) +
                        (
                            item.price ? '<span class="item-price">$' + escape(item.price) + '</span>' : ''
                        ) +
                        '</div>'
                    );
                },
                item: function (item, escape) {

                    var extra = '';
                    if (item.discontinued) {
                        extra =
                            extra + '<em>&nbsp;<span class="label discontinued" style="float: none; width: auto; margin: 0 10px; font-size:6px; position: relative;top: -2px;">DISCONTINUED</span></em>';
                    }

                    if (item.out_of_stock) {
                        extra =
                            extra + '<em>&nbsp;<span class="label warning" style="float: none; width: auto; margin: 0 10px; font-size:6px; position: relative;top: -2px;">OUT OF STOCK</span></em>';
                    }

                    return (
                        '<div>'
                        + '<span class="item-title truncate">' + escape(item.title) + ' ' + extra + '</span>'
                        + '<span class="item-number">Item #: ' + escape(item.item_number) + '</span>'
                        + '</div>'
                    );
                }
            },
            onChange: function (value) {
                var $itemPrice = $('.price-list > [data-item-id="' + value + '"]'),
                    $unitPriceInput = $('input[name="unit_price"]');

                $('.price-list > li[data-item-id]').hide();

                if ($itemPrice.length == 1) {
                    $itemPrice.show();
                }
                else {
                    $('.price-list > li.hidden').show();
                }

                if ($unitPriceInput.length > 0) {
                    $unitPriceInput.val($itemPrice.data('itemUnitPrice'));
                }
            },

            onInitialize: function () {
                var $itemPrice = $(
                        '.price-list > [data-item-id="' + $itemSelector.data('selectize').getValue() + '"]'),
                    $unitPriceInput = $('input[name="unit_price"]');

                $('.price-list > li[data-item-id]').hide();

                if ($itemPrice.length == 1) {
                    $itemPrice.show();
                }
                else {
                    $('.price-list > li.hidden').show();
                }

                if ($unitPriceInput.length > 0) {
                    $unitPriceInput.val($itemPrice.data('itemUnitPrice'));
                }
            }
        });
    };

    PortOneFive.AddItemToListControl = function ($addItemToListControl) {
        $addItemToListControl.find('input').on('keyup input', function () {
            $addItemToListControl.find('.item-subtotal').text('$' + parseFloat(
                    parseInt($addItemToListControl.find('input[name="quantity"]').val()) *
                    parseFloat($addItemToListControl.find('input[name="unit_price"]').val())
                ).toFixed(2));
        });
    };

    PortOneFive.register('.AddToFavoritesButton', 'PortOneFive.AddToFavoritesButton');
    PortOneFive.register('.ItemSelector', 'PortOneFive.ItemSelector');
    PortOneFive.register('.AddItemToListControl', 'PortOneFive.AddItemToListControl');

}(jQuery, this, document);
