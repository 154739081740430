/** @param {jQuery} $ jQuery Object */
!function ($, window, document, _undefined) {

  PortOneFive.Pusher = new Pusher('b6fecc328fb7adbabeac', {
    encrypted: true
  });

  $(document).ready(function () {
    // Enable pusher logging - don't include this in production
    Pusher.log = function (message) {
      //if (window.console && window.console.log) {
      //window.console.log(message);
      //}
    };

    var userChannel = PortOneFive.Pusher.subscribe('visitor_' + PortOneFive.visitor.id);
    userChannel.bind('Clamp\\Events\\ShoppingListSwitched', function () {
      location.reload();
    });
  });

  PortOneFive.SidebarToggle = function ($sidebarToggle) {
    $sidebarToggle.on('click', function () {
      $('#sidebar').toggleClass('hide');
    });
  };

  PortOneFive.ShoppingList = function ($shoppingList) {

    var channel = PortOneFive.Pusher.subscribe('shopping_list_' + $shoppingList.data('shopping-list-id'));
    channel.bind('Clamp\\Events\\ItemAddedToShoppingList', function (data) {
      var $shoppingListItem = $(
        '<li class="ShoppingListItem clearfix" data-product-id="'
        + data.item.id
        + '">'
        + '<a href="'
        + data.item.href
        + '" class="clearfix">'
        + '<img src="'
        + data.item.thumbnail
        + '" alt=""/>'
        + '<div class="item-info">'
        + '<h6 class="product-title">'
        + data.item.title
        + '</h6>'
        + '<div class="bottom">'
        + '<span class="item-number"># <em>'
        + data.item.item_number
        + '</em></span>'
        + (
          data.showPrice
            ? '<span class="price">&nbsp;@&nbsp;$&nbsp;'
          + data.price
          + ' ea.</span>'
            : null
        )
        + '<span class="quantity">'
        + data.quantity
        + ' @ '
        + data.price
        + ' = $ '
        + parseFloat(data.quantity
          * data.price).toFixed(2)
        + '</span>'
        + '</div>'
        + '</div>'
        + '<span class="delete" data-href="/item/'
        + data.item.id
        + '/remove-from-list">&times;</span>'
        + '</a>'
        + '</li>');

      $shoppingListItem.portOneFiveInsert('appendTo', $shoppingList);
      $shoppingListItem.portOneFiveActivate();
    });

    channel.bind('Clamp\\Events\\ShoppingListUpdated', function (data) {
      var $shoppingListSubtotal = $('#shopping-list-subtotal');
      $shoppingListSubtotal.text(data.shoppingList.subtotal);
    });

    channel.bind('Clamp\\Events\\ItemRemovedFromShoppingList', function (data) {
      $shoppingList.find('[data-product-id="' + data.itemId + '"]').portOneFiveRemove();
    });

    $shoppingList.on('click', '> .ShoppingListItem > a', function (e) {
      var $target = $(e.target);

      e.preventDefault();

      if ($target.is('.delete')) {
        var request = new PortOneFive.Ajax($target.data('href'));

        request.post().done(function () {
          $target.closest('.ShoppingListItem').portOneFiveRemove();
        }.bind(this));

        return false;
      }

      PortOneFive.PJAXLoader($(this).attr('href'));
    });
  };

  PortOneFive.SidebarTabs = function ($sidebarTabs) {

    const $tabs = $sidebarTabs.find('> [data-tab]');
    const $listElements = $tabs.find('> li');
    const $contentContainers = $sidebarTabs.find('.tabs-content > .content');

    if ($.getCookie('sidebar-panel') != null) {

      $listElements.removeClass('active');
      $contentContainers.removeClass('active');

      $listElements.eq($.getCookie('sidebar-panel')).addClass('active');
      $contentContainers.eq($.getCookie('sidebar-panel')).addClass('active');
    }

    $tabs.on('toggled', (e, tab) => $.setCookie('sidebar-panel', tab.index()));
  };

  PortOneFive.register('.ShoppingList', 'PortOneFive.ShoppingList');
  PortOneFive.register('.SidebarToggle', 'PortOneFive.SidebarToggle');
  PortOneFive.register('.SidebarTabs', 'PortOneFive.SidebarTabs');

}(jQuery, this, document);
