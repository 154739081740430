/** @param {jQuery} $ jQuery Object */
!function ($, window, document, _undefined) {

    PortOneFive.LeadSearchForm = function ($leadSearchForm) {
        this.__construct($leadSearchForm);
    };

    PortOneFive.LeadSearchForm.prototype = {

        selectedRegions: [],

        __construct: function ($leadSearchForm) {
            this.$leadSearchForm = $leadSearchForm;

            this.initializeMap();

            console.log('test');

            $('#map-tabs').find('> li:first-child > a').click();
        },

        initializeMap: function () {
            $('#map-tabs').on('toggled', function (event, tab) {

                var $tabContent = $(tab.find('a').attr('href'));

                $tabContent.find('[data-map="usa"]').cssMap({
                    size: 810,
                    tooltips: "floating",
                    multipleClick: true,
                    searchLink: '',
                    onClick: function ($element) {
                        var regionId = $element.find('a').attr('href').replace('#', '');
                        this.selectedRegions.push(regionId);
                        this.setSelectedRegions(this.selectedRegions);
                    }.bind(this),
                    onSecondClick: function ($element) {
                        var regionId = $element.find('a').attr('href').replace('#', ''),
                            index = this.selectedRegions.indexOf(regionId);

                        if (index > -1) {
                            delete this.selectedRegions[index];
                        }

                        this.setSelectedRegions(this.selectedRegions);

                    }.bind(this)
                });

                $tabContent.find('[data-map="canada"]').cssMap({
                    size: 850,
                    tooltips: "floating",
                    multipleClick: true,
                    searchLink: '',
                    onClick: function ($element) {
                        var regionId = $element.find('a').attr('href').replace('#', '');
                        this.selectedRegions.push(regionId);
                        this.setSelectedRegions(this.selectedRegions);
                    }.bind(this),
                    onSecondClick: function ($element) {
                        var regionId = $element.find('a').attr('href').replace('#', ''),
                            index = this.selectedRegions.indexOf(regionId);
                        if (index > -1) {
                            delete this.selectedRegions[index];
                        }
                        this.setSelectedRegions(this.selectedRegions);
                    }.bind(this)
                });
            }.bind(this));
        },

        setSelectedRegions(regions)
        {
            $('[name="regions"]').val($.grep(regions, function (n) {
                return n;
            }).join('-'));
        }
    };

    PortOneFive.SicCodeSelector = function ($selector) {
        $selector.selectize({
            searchField: ['value', 'text'],
            dropdown_direction: 'up',
            render: {
                option: function (option) {
                    return '<div><span style="display: inline-block; width: 40px; text-align: left; font-weight: 700; margin-right: 5px;">'
                           + option.value
                           + '</span><span>'
                           + option.text
                           + '</span></div>';
                },
                item: function (option) {
                    return '<div style="display: inline;"><span class="label">' + option.value + '</span><span>' + option.text + '</span></div>';
                }
            },
            onChange: function (value) {
                $('.LeadSearchMap .usa li').each(function (index, stateListElement) {
                    var $state = $(stateListElement),
                        codeTotal = 0;

                    $(value).each(function (index, sicCode) {
                        if (typeof $state.attr('data-sic-' + sicCode) !== 'undefined') {
                            codeTotal = codeTotal + parseInt($state.attr('data-sic-' + sicCode));
                        }
                    });

                    $state.find('.state-num-leads').text(codeTotal);

                    if (codeTotal == 0) {
                        $state.addClass('disabled-region');
                    } else {
                        if ($state.hasClass('disabled-region') || value.length == 0) {
                            $state.removeClass('disabled-region');
                        }
                    }
                });
            }
        });
    };

    PortOneFive.register('.LeadSearchForm', 'PortOneFive.LeadSearchForm');
    PortOneFive.register('.SicCodeSelector', 'PortOneFive.SicCodeSelector');

}(jQuery, this, document);
