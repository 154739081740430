/** @param {jQuery} $ jQuery Object */
!function ($, window, document, _undefined) {

  PortOneFive.Selectize = function ($selector) {
    $selector.selectize({
      create: $selector.dataOrDefault('selectizeCreate', false)
    });
  };

  PortOneFive.NodeQuickChangeSelector = function ($selector) {
    if ($selector.hasClass('selectized')) {
      $selector.on('change', function (event) {
        PortOneFive.PJAXLoader(event.target.value).done(function (ajaxData) {
          window.history.pushState({}, ajaxData.title, event.target.value);
        });

        return false;
      });
    }
  };

  PortOneFive.Search = function ($searchContainer) {
    var $searchInput = $searchContainer.find('input[type="search"]'),
      $resultsContainer = $searchContainer.find('#search-results-container');

    var request, searchTimer;

    $searchInput.on('keyup', function () {
      if ($searchInput.val().length < 3) {
        $resultsContainer.find('#search-results > li').remove();
        return false;
      }

      if (request) {
        return;
      }

      clearTimeout(searchTimer);

      searchTimer = setTimeout(function () {
        request = new PortOneFive.Ajax($searchInput.data('url'));
        request.post({ q: $searchInput.val() }).done(
          function () {
            $searchContainer.addClass('open');
            $resultsContainer.show();

            $('body').one('click', function (e) {
              if (!$searchContainer.is(e.target) && $searchContainer.has(e.target).length === 0) {
                $searchContainer.removeClass('open');
                $resultsContainer.hide();
              }
            });

            clearTimeout(searchTimer);
            request = null;
            $searchInput.focus();

          }.bind(this)
        );
      }, 200);
    }).on('blur', function () {
      $resultsContainer.hide();
    });
  };

  PortOneFive.UserFavorites = function ($userFavorites) {
    $userFavorites.find('> ul').sortable({
      update: function (event, ui) {
        var request = new PortOneFive.Ajax('/favorite/update-display-order');
        var productIds = [];

        $userFavorites.find('> ul > li').each(function (i, favoriteItem) {
          productIds.push($(favoriteItem).data('productId'));
        });

        request.post({ products: productIds });
      }
    });
  };

  PortOneFive.WYSIWYG = function ($WYSIWYG) {
    //$WYSIWYG.tinymce(
    //    {
    //        skin : 'charcoal',
    //
    //        plugins: [
    //            "advlist autolink lists link image charmap print preview anchor",
    //            "searchreplace visualblocks code fullscreen",
    //            "insertdatetime media table contextmenu paste"
    //        ],
    //
    //        toolbar: "undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link"
    //    }
    //);
  };

  if ($.support.pjax) {
    //$(document).on(
    //    'click', 'a[data-pjax]', function (event)
    //    {
    //        var container = $('#container');
    //        $.pjax.click(event, {container: container});
    //    }
    //)
  }

  PortOneFive.OpenSalesPitchSlider = function ($link) {

    var $salesPitches = $('#sales-pitches');
    var openCookie = $.getCookie('salesPitchesAreOpen');

    if (openCookie) {
	$('#content').css('max-height','calc(100% + -330px)')
      $salesPitches.addClass('open');
    }

    $link.on('click', function (e) {

      e.preventDefault();
      $(this).toggleClass('active');

      $salesPitches.toggleClass('open');

      if ($salesPitches.hasClass('open')) {
	$('#content').css('max-height','calc(100% + -330px)')
        $.setCookie('salesPitchesAreOpen', true);
      } else {
	$('#content').css('max-height','calc(100% + -80px)')
        $.deleteCookie('salesPitchesAreOpen');
      }
    });
  };

  PortOneFive.register('select:not(.native)', 'PortOneFive.Selectize');
  PortOneFive.register('.Search', 'PortOneFive.Search');
  PortOneFive.register('.UserFavorites', 'PortOneFive.UserFavorites');
  PortOneFive.register('.WYSIWYG', 'PortOneFive.WYSIWYG');
  PortOneFive.register('.OpenSalesPitchSlider', 'PortOneFive.OpenSalesPitchSlider');
  PortOneFive.register('.NodeQuickChangeSelector', 'PortOneFive.NodeQuickChangeSelector');

}(jQuery, this, document);
