/** @param {jQuery} $ jQuery Object */
!function ($, window, document, _undefined) {
    PortOneFive.OverlayManager = {

        init: function () {
        }
    };

    $.extend(
        PortOneFive, {

            /**
             * Cache for overlays
             *
             * @var object
             */
            _OverlayCache: {},

            /**
             * Configuration for overlays, should be redefined in the PAGE_CONTAINER template HTML
             *
             * @var object
             */
            _overlayConfig: {},

            /**
             * Enable overlays or use regular pages
             *
             * @var boolean
             */
            _enableOverlays: true,

            /**
             * Creates an overlay using the given HTML
             *
             * @param $trigger
             * @param html
             * @param extraOptions
             *
             * @return jQuery Overlay API
             */
            createOverlay: function ($trigger, html, extraOptions) {
                var $overlay = null,
                    $html = null,
                    regex = /<script[^>]*>([\s\S]*?)<\/script>/ig,
                    regexMatch,
                    scripts = [],
                    i;

                if (html instanceof jQuery && html.is('.reveal-modal')) {
                    // this is an object that has already been initialised
                    $overlay = html;
                }
                else {
                    if (typeof(
                            html
                        ) == 'string') {
                        while (regexMatch = regex.exec(html)) {
                            scripts.push(regexMatch[1]);
                        }
                        html = html.replace(regex, '');
                    }

                    $html = $(html);

                    // add a cancel button to the overlay, if the overlay is a form, has a .submit-unit but has no :reset button
                    if ($html.is('form')) {
                        if ($html.find('.submit-unit').length) {
                            if (!$html.find('.submit-unit :reset').length) {
                                $html.find('.submit-unit input:last')
                                     .before($('<input type="reset" class="close-reveal-modal" value="Cancel"/>'));
                            }
                            else {
                                $html.find('.submit-unit :reset').addClass('close-reveal-modal');
                            }
                        }
                    }

                    // create an overlay container, add the activated template to it and append it to the body.
                    $overlay =
                        $('<div class="reveal-modal __PortOneFiveActivator" data-reveal></div>')
                            .append('<div class="row"><div class="modal-content columns small-12"></div></div>')
                            .addClass($html.data('overlay-class'))
                            .appendTo($('body'));

                    var $modalContent = $overlay.find('.modal-content');

                    if (!$html.is('.NoAutoHeader') && extraOptions && extraOptions.title) {
                        $('<h1 class="title" />').html(extraOptions.title).prependTo($modalContent);
                    }

                    $modalContent.append($html).portOneFiveActivate();

                    if (scripts.length) {
                        for (i = 0; i < scripts.length; i++) {
                            $.globalEval(scripts[i]);
                        }
                    }
                }

                if (extraOptions) {
                    if (extraOptions.className) {
                        $overlay.addClass(extraOptions.className);
                        delete(
                            extraOptions.className
                        );
                    }
                }

                // add an overlay closer if one does not already exist
                if ($overlay.find('.close-reveal-modal:not(input)').length == 0) {
                    $overlay.prepend('<a class="close close-reveal-modal">&#215;</a>');
                }

                // if no trigger was specified (automatic popup), then activate the overlay instead of the trigger
                $trigger = $trigger || $overlay;

                // activate the overlay
                $trigger.overlay(
                    $.extend(
                        true,
                        {
                            target: $overlay,
                            oneInstance: true
                        },
                        PortOneFive._overlayConfig,
                        extraOptions
                    )
                );

                var overlay = $trigger.data('overlay');

                var resizeClose = function () {
                    console.info('Window resize, close overlay!');
                    overlay.close();
                };

                overlay.opening(
                    function () {
                        if (overlay.getConf().closeOnResize) {
                            $(window).one('resize', resizeClose);
                        }
                    }
                );

                overlay.opened(
                    function () {
                        var $focus = $overlay.find('form')
                                             .find(
                                                 'input[autofocus], textarea[autofocus], select[autofocus], .AutoFocus')
                                             .first();

                        if ($focus.length) {
                            $focus.focus();
                        }
                        else {
                            $overlay.find('form')
                                    .find('input:not([type=hidden]), textarea, select, button, .submit-unit a.button')
                                    .first()
                                    .focus();
                        }
                    }
                );

                overlay.closed(
                    function () {
                        if (overlay.getConf().closeOnResize) {
                            $(window).unbind('resize', resizeClose);
                        }
                    }
                );

                $overlay.data('overlay', overlay);

                return overlay;
            }
        }
    );

    PortOneFive.Overlay = function ($trigger, conf) {
        this.__construct($trigger, conf);
    };

    PortOneFive.Overlay.prototype =
    {
        defaultConfig: {
            oneInstance: true,
            backdrop: true,
            closeOnResize: false,
            cache: true,
            show: false
        },

        __construct: function ($trigger, conf) {
            this.visible = false;

            this._openingCallbacks = [];
            this._openedCallbacks = [];
            this._closingCallbacks = [];
            this._closedCallbacks = [];

            this.$trigger = $trigger;
            this.conf = $.extend(this.defaultConfig, conf);

            var target = this.conf.target || this.$trigger.attr('rel');

            this.$overlay = target ? $(target) : this.$trigger;

            if (!this.$overlay.length) {
                throw "Could not find Overlay: " + target;
            }

            this.$listeners = this.$trigger.add(this.$overlay);

            this.$overlay.bind(
                {
                    'open.fndtn.reveal': $.context(this, 'onOpen'),
                    'close.fndtn.reveal': $.context(this, 'onClose'),
                    'opened.fndtn.reveal': $.context(this, 'onOpened'),
                    'closed.fndtn.reveal': $.context(this, 'onClosed')
                }
            );

            this.closers = this.getOverlay().find(this.getConf().close || ".close-reveal-modal");

            $(document).foundation('reveal', 'reflow');

            if (this.conf.show) {
                this.open();
            }
        },

        addListener: function ($listener) {
            this.getListeners().add($listener);

            return this;
        },

        opening: function (callback) {
            this._openingCallbacks.push(callback);

            return this;
        },

        opened: function (callback) {
            this._openedCallbacks.push(callback);

            return this;
        },

        closing: function (callback) {
            this._closingCallbacks.push(callback);

            return this;
        },

        closed: function (callback) {
            this._closedCallbacks.push(callback);

            return this;
        },

        open: function (onOpened) {
            if (!this.isOpened()) {
                if ($.isFunction(onOpened)) {
                    this.opened(onOpened);
                }

                this.getOverlay().foundation('reveal', 'open');
            }

            return this;
        },

        close: function (onClosed) {
            if (this.isOpened()) {
                this.getOverlay().foundation('reveal', 'close');
                this.closed(onClosed);
            }

            return this;
        },

        deCache: function () {
            this.getOverlay().remove();
            this.getTrigger().removeData('overlay');

            return this;
        },

        onOpen: function (e) {
            this.visible = true;

            if (e.isDefaultPrevented()) {
                return this;
            }

            $(document).triggerHandler('OverlayOpening');

            var $body = $('body');

            if (!$body.hasClass('modal-open')) {
                //                $('#OverlayContainer').show();

                $body.addClass('modal-open');

                if (!this.getConf().backdrop) {
                    $body.addClass('no-modal-bg');
                }
            }

            if (this.getConf().oneInstance) {
                var self = this;

                $.each(
                    $('[data-reveal]:visible'), function () {
                        if (self !== $(this).data('overlay')) {
                            $(this).data('overlay').close();
                        }
                    }
                );
            }

            $.each(
                this._openingCallbacks, $.context(
                    function (i, callback) {
                        if ($.isFunction(callback)) {
                            callback(e, this);
                        }
                    }, this
                )
            );
        },

        onOpened: function (e) {
            $(document).triggerHandler('OverlayOpened');

            $.each(
                this._openedCallbacks, $.context(
                    function (i, callback) {
                        if ($.isFunction(callback)) {
                            callback(e, this);
                        }
                    }, this
                )
            );
        },

        onClose: function (e) {
            $.each(
                this._closingCallbacks, $.context(
                    function (i, callback) {
                        if ($.isFunction(callback)) {
                            callback(e, this);
                        }
                    }, this
                )
            );
        },

        onClosed: function (e) {
            if ($('[data-reveal]:visible').length == 0) {
                $('body').removeClass('modal-open');
                //                $('#OverlayContainer').hide();
            }

            this.visible = false;

            if (!this.getConf().backdrop) {
                $('body').removeClass('no-modal-bg');
            }

            if (!this.getConf().cache) {
                this.deCache();
            }

            $(window).scrollTop(0);

            $.each(
                this._closedCallbacks, $.context(
                    function (i, callback) {
                        if ($.isFunction(callback)) {
                            callback(e, this);
                        }
                    }, this
                )
            );
        },

        getOverlay: function () {
            return this.$overlay;
        },

        getTrigger: function () {
            return this.$trigger;
        },

        getClosers: function () {
            return this.closers;
        },

        getListeners: function () {
            return this.$listeners;
        },

        isOpened: function () {
            return this.visible;
        },

        getConf: function () {
            return this.conf;
        }
    };

    // *********************************************************************

    /**
     * Triggers an overlay from a regular link or button
     * Triggers can provide an optional data-cacheOverlay attribute
     * to allow multiple trigers to access the same overlay.
     *
     * @param jQuery .OverlayTrigger
     */
    PortOneFive.OverlayTrigger = function ($trigger, options) {
        this.__construct($trigger, options);
    };
    PortOneFive.OverlayTrigger.prototype =
    {
        __construct: function ($trigger, options) {
            this.$trigger = $trigger.click($.context(this, 'show'));
            this.options = options;
        },

        /**
         * Begins the process of loading and showing an overlay
         *
         * @param event e
         */
        show: function (e) {
            var parentOverlay = this.$trigger.closest('.reveal-modal').data('overlay'),
                cache,
                options;

            if (!PortOneFive._enableOverlays) {
                // if no overlays, use <a href /> by preference
                if (this.$trigger.attr('href')) {
                    return true;
                } else if (this.$trigger.data('href')) {
                    PortOneFive.redirect(this.$trigger.data('href'));
                    return false;
                } else {
                    // can't do anything - should not happen
                    console.warn('No alternative action found for OverlayTrigger %o', this.$trigger);
                    return true;
                }
            }

            // abort if the event has a modifier key or is a middle or right-button click
            if (e.ctrlKey || e.shiftKey || e.altKey || e.which > 1) {
                return true;
            }

            if (this.options && this.options.onBeforeTrigger) {
                var newE = $.Event();
                newE.clickEvent = e;
                this.options.onBeforeTrigger(newE);
                if (newE.isDefaultPrevented()) {
                    return;
                }
            }

            e.preventDefault();

            if (parentOverlay && parentOverlay.isOpened()) {
                var self = this;

                parentOverlay.closed(function (innerE) {
                    self.show(innerE);
                });

                parentOverlay.close();

                return;
            }

            if (!this.OverlayLoader) {
                options = typeof this.options == 'object' ? this.options : {};
                options = $.extend(options, this.$trigger.data('overlayOptions'));

                options.cache = cache = PortOneFive.isPositive(this.$trigger.data('cacheOverlay'));

                if (!cache || this.$trigger.is(':submit')) {
                    options.cache = cache = false;
                    options.deCache = $.context(this, 'deCache');
                }

                this.OverlayLoader = new PortOneFive.OverlayLoader(this.$trigger, options, cache);

                this.OverlayLoader.load();

                e.preventDefault();
                return true;
            }

            this.OverlayLoader.load();
        },

        //        deCache: function ()
        //        {
        //            alert('deCachhe');
        //
        //            if (this.OverlayLoader && this.OverlayLoader.$overlay)
        //            {
        //                console.info('DeCache %o', this.OverlayLoader.$overlay.getOverlay());
        //
        //                this.OverlayLoader.$overlay.getTrigger().removeData('overlay');
        //
        //                var $overlay = this.OverlayLoader.$overlay.getOverlay();
        //
        //                delete(PortOneFive.OverlayLoaderCache[this.OverlayLoader.$overlay.getTrigger().attr('href')]);
        //
        //                $overlay.empty().remove();
        //            }
        //
        //            delete(this.OverlayLoader);
        //        }
    };

    // *********************************************************************

    PortOneFive.OverlayLoaderCache = {};

    /**
     * Loads HTML and related external resources for an overlay
     *
     * @param $trigger jQuery Overlay trigger object
     * @param {boolean} cache If true, cache the overlay HTML for this URL
     * @param {object} options Object of options for the overlay
     */
    PortOneFive.OverlayLoader = function ($trigger, options, cache) {
        this.__construct($trigger, options, cache);
    };
    PortOneFive.OverlayLoader.prototype =
    {
        __construct: function ($trigger, options, cache) {
            this.$trigger = $trigger;
            this.options = options;
            this.cache = cache;
        },

        /**
         * Initiates the loading of the overlay, or returns it from cache
         *
         * @param callback
         */
        load: function (callback) {
            if (this.$trigger.is(':submit')) {
                this.cache = false;

                if (!this.xhr) {
                    var $form = this.$trigger.closest('form'),
                        serialized = $form.serializeArray();

                    serialized.push(
                        {
                            name: this.$trigger.attr('name'),
                            value: this.$trigger.attr('value')
                        }
                    );

                    this.xhr = new PortOneFive.Ajax($form.attr('action'));

                    if ($form.attr('method').toLowerCase() == 'get') {
                        this.xhr.get(serialized).done($.context(this, 'loadSuccess'));
                    }
                    else {
                        this.xhr.post(serialized).done($.context(this, 'loadSuccess'));
                    }
                }

                return;
            }

            this.href = this.$trigger.data('href') || this.$trigger.attr('href');

            this.callback = callback;

            if (!this.href) {
                this.href = this.$trigger.dataOrDefault('rel', null);

                if (this.href !== null) {
                    console.info('OverlayLoader for %s', this.href);

                    this.createOverlay($(this.href));

                    return;
                }

                console.warn('No overlay href found for control %o', this.$trigger);

                return false;
            }

            console.info('OverlayLoader for %s', this.href);

            if (this.cache && PortOneFive.OverlayLoaderCache[this.href]) {
                this.createOverlay(PortOneFive.OverlayLoaderCache[this.href]);
            }
            else if (!this.xhr) {
                this.xhr = new PortOneFive.Ajax(this.href);

                this.xhr.get().done($.context(this, 'loadSuccess'));
            }
        },

        /**
         * Handles the returned ajaxdata from an overlay xhr load,
         * Stores the template HTML then inits externals (js, css) loading
         *
         * @param ajaxData
         * @param textStatus
         */
        loadSuccess: function (ajaxData, textStatus) {
            delete(this.xhr);
            this.options.title = ajaxData.h1 || ajaxData.title;
            this.createOverlay(ajaxData);
        },

        /**
         * Creates an overlay containing the appropriate template HTML,
         * runs the callback specified in .load() and then shows the overlay.
         *
         * @param $overlay
         */
        createOverlay: function ($overlay) {
            var contents = (
                               $overlay && $overlay.response && $overlay.response.html
                           )
                ? $overlay.response.html
                : $overlay;

            this.$overlay = PortOneFive.createOverlay(this.$trigger, contents, this.options);

            if (this.cache) {
                PortOneFive.OverlayLoaderCache[this.href] = this.$overlay;
            }

            if (typeof this.callback == 'function') {
                this.callback();
            }

            this.show();
        },

        /**
         * Shows a finished overlay
         */
        show: function () {
            if (!this.$overlay) {
                console.warn(
                    'Attempted to call PortOneFive.OverlayLoader.show() for %s before overlay is created',
                    this.href
                );
                this.load(this.callback);
                return;
            }

            this.$overlay.open();
        },

        /**
         *
         * @returns {PortOneFive.Overlay}
         */
        getOverlay: function () {
            if (!this.$overlay) {
                console.warn(
                    'Attempted to call PortOneFive.OverlayLoader.getOverlay() for %s before overlay is created',
                    this.href
                );
                this.load(this.callback);
                return;
            }

            return this.$overlay;
        }
    };

    // Register overlay-loading controls
    PortOneFive.register(
        'a.OverlayTrigger, input.OverlayTrigger, button.OverlayTrigger, label.OverlayTrigger',
        'PortOneFive.OverlayTrigger'
    );

}
(jQuery, this, document);
