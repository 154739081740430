/** @param {jQuery} $ jQuery Object */
!function ($, window, document, _undefined) {

    PortOneFive.DispositionStatusIndicator = function ($statusIndicator) {
        this.__construct($statusIndicator);
    };

    PortOneFive.DispositionStatusIndicator.prototype = {

        __construct: function ($statusIndicator) {
            this.$statusIndicator = $statusIndicator;
            this.$statusIndicator.on('click', this._onClick.bind(this));
        },

        _onClick: function (event) {

            event.preventDefault();
            event.stopPropagation();

            this.OverlayLoader = new PortOneFive.OverlayLoader(this.$statusIndicator, {}, false);
            this.OverlayLoader.load();

            return false;
        }
    };

    PortOneFive.CallLead = function ($callLeadButton) {
        this.__construct($callLeadButton);
    };

    PortOneFive.CallLead.prototype = {
        __construct: function ($callLeadButton) {
            this.$claimLeadButton = $callLeadButton;
            this.$claimLeadButton.on('click', this._onClick.bind(this));
        },

        _onClick: function (event) {
            event.preventDefault();
            event.stopPropagation();

            var request = new PortOneFive.Ajax(this.$claimLeadButton.attr('href'));

            request.get();

            return false;
        }
    };

    PortOneFive.ClaimLead = function ($callLeadButton) {
        this.__construct($callLeadButton);
    };

    PortOneFive.ClaimLead.prototype = {
        __construct: function (claimLeadButton) {
            this.$claimLeadButton = claimLeadButton;
            this.$claimLeadButton.on('click', this._onClick.bind(this));
        },

        _onClick: function (event) {
            event.preventDefault();
            event.stopPropagation();

            var request = new PortOneFive.Ajax(this.$claimLeadButton.attr('href'));

            request.post().done(function (ajaxData) {
                if (ajaxData.status == 'claimed') {
                    this.$claimLeadButton.addClass('claimed');
                } else {
                    this.$claimLeadButton.removeClass('claimed');
                }
            }.bind(this));

            return false;
        }
    };


    PortOneFive.SetLeadActive = function (setLeadActiveButton) {
        this.__construct(setLeadActiveButton);
    };

    PortOneFive.SetLeadActive.prototype = {
        __construct: function ($setLeadActiveButton) {
            this.$setLeadActiveButton = $setLeadActiveButton;
            this.$setLeadActiveButton.on('click', this._onClick.bind(this));
        },

        _onClick: function (event) {
            event.preventDefault();
            event.stopPropagation();

            (new PortOneFive.Ajax(this.$setLeadActiveButton.attr('href'))).post();

            return false;
        }
    };

    PortOneFive.register('.DispositionStatusIndicator', 'PortOneFive.DispositionStatusIndicator');
    PortOneFive.register('.CallLead', 'PortOneFive.CallLead');
    PortOneFive.register('.SetLeadActive', 'PortOneFive.SetLeadActive');
    PortOneFive.register('.ClaimLead', 'PortOneFive.ClaimLead');

}(jQuery, this, document);
