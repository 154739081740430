/** @param {jQuery} $ jQuery Object */
!function ($, window, document, _undefined) {
  PortOneFive.Ajax = function (url, options) {
    this.__construct(url, options)
  };

  PortOneFive.Ajax.prototype = {

    submitPending: false,

    __construct: function (url, options) {
      if (!url) {
        return console.error('No url specified for PortOneFive.Ajax');
      }

      this.url = PortOneFive.canonicalizeUrl(url, PortOneFive.ajaxBaseHref);

      this.options = $.extend(
        true, {
          cache: false,
          preventMetaHandling: false,
          preventRedirects: true,
          timeout: 30000,
          type: 'POST',
          dataType: 'json',
          xhrFields: {
            withCredentials: true
          },
          beforeSend: function (xhr) {
          },
          tusCode: {
            200: function (ajaxData, textStatus) {
              console.log(ajaxData, textStatus);
              // response was success
            },
            422: function (jqXHR, ajaxData, textStatus) {
              // handle validation error
            },
            404: function (jqXHR, ajaxData, textStatus) {
              // handle 404
            }
          }
        }, { url: this.url }, options
      );

      this.$listener = $({});
    },

    setMethod: function (method) {
      this.options.type = method;

      return this;
    },

    get: function (data) {
      return this.setMethod('get').send(data);
    },

    post: function (data) {
      return this.setMethod('post').send(data);
    },

    put: function (data) {
      return this.setMethod('put').send(data);
    },

    send: function (data, success, error, always) {
      this.data = data || {};

      var eDataSend = $.Event('AjaxBeforeSend');

      eDataSend.url = this.url;
      eDataSend.data = this.data;
      eDataSend.options = this.options;
      eDataSend.halt = false;

      this.$listener.trigger(eDataSend);

      if (eDataSend.halt || eDataSend.isDefaultPrevented()) {
        return false;
      }

      this.options.headers = { 'X-Ajax-Referer': window.location.url, 'X-XSRF-TOKEN': PortOneFive._token };
      this.options.data = this.data;

      this.submitPending = true;

      return this.$jqXHR = $.ajax(this.options).pipe(
        function (data, textStatus, jqXHR) {
          return jqXHR.status != 200 ? $.Deferred().reject(data) : data;
        }
      ).fail($.context(this, 'errorCallback')).done($.context(this, 'successCallback'));
    },

    successCallback: function (ajaxData, textStatus, xhr) {

      console.log(ajaxData, textStatus, arguments);

      this.submitPending = false;

      if (!ajaxData) {
        console.warn('No ajax data returned.');
        return false;
      }

      var eDataReceive = $.Event('AjaxDataReceived');
      eDataReceive.ajaxData = ajaxData;
      eDataReceive.textStatus = textStatus;

      console.group('Event: %s', eDataReceive.type);
      this.$listener.trigger(eDataReceive);
      console.groupEnd();

      if (eDataReceive.isDefaultPrevented()) {
        return false;
      }

      if (this.hasResponseError(ajaxData, this.$jqXHR.status)) {
        return false;
      }

      console.group('Event: PortOneFiveAjaxSuccess');
      $(document).trigger(
        {
          type: 'PortOneFiveAjaxSuccess',
          ajaxData: ajaxData,
          textStatus: textStatus
        }
      );

      this.handleMetaData(ajaxData);

      console.groupEnd();
    },

    errorCallback: function (xhr, textStatus, errorThrown) {
      if (xhr.readyState == 0) {
        return;
      }

      try {
        this.successCallback($.parseJSON(xhr.responseText), textStatus);
      }
      catch (e) {
        this.handleServerError(xhr, textStatus, errorThrown);
      }
    },

    /**
     * Generic handler for server-level errors received from PortOneFive.Ajax
     * Attempts to provide a useful error message.
     *
     * @param {object} xhr XMLHttpRequest
     * @param {string} responseText Response text
     * @param {string} errorThrown Error thrown
     *
     * @return boolean False
     */
    handleServerError: function (xhr, responseText, errorThrown) {
      // handle timeout and parse error before attempting to decode an error
      switch (responseText) {
        case 'abort': {
          return false;
        }
        case 'timeout': {
          PortOneFive.alert('The server timed out', 'error');
          return false;
        }
        case 'parsererror': {
          console.error('PHP ' + xhr.responseText);
          PortOneFive.alert(
            'The server responded with an error. The error message is in the JavaScript console.',
            'error'
          );
          return false;
        }
        case 'notmodified':
        case 'error': {
          if (!xhr || !xhr.responseText) {
            // this is likely a user cancellation, so just return
            return false;
          }
          break;
        }
      }

      var contentTypeHeader = xhr.getResponseHeader('Content-Type'), contentType = false, data;

      if (contentTypeHeader) {
        switch (contentTypeHeader.split(';')[0]) {
          case 'application/json': {
            contentType = 'json';
            break;
          }
          case 'text/html': {
            contentType = 'html';
            break;
          }
          default: {
            if (xhr.responseText.substr(0, 1) == '{') {
              contentType = 'json';
            }
            else if (xhr.responseText.substr(0, 9) == '<!DOCTYPE') {
              contentType = 'html';
            }
          }
        }
      }

      if (contentType == 'json' && xhr.responseText.substr(0, 1) == '{') {
        // XMLHttpRequest response is probably JSON
        try {
          data = $.parseJSON(xhr.responseText);
        }
        catch (e) {
        }

        if (data) {
          this.hasResponseError(data, xhr.status);
        }
        else {
          PortOneFive.alert(xhr.responseText, 'error');
        }
      }
      else {
        // XMLHttpRequest is some other type...
        var $alertHtml = PortOneFive.alert(xhr.responseText, 'error');
        $alertHtml.addClass('large').addClass('exception');
      }

      return false;
    },

    /**
     * Checks for the presence of an 'error' key in the provided data
     * and displays its contents if found, using an alert.
     *
     * @param {object} ajaxData ajaxData
     * @param {int} httpErrorCode HTTP error code (optional)
     *
     * @return boolean|string Returns the error string if found, or false if not found.
     */
    hasResponseError: function (ajaxData, httpErrorCode) {
      if (typeof ajaxData != 'object') {
        PortOneFive.alert('Response not JSON!', 'error');
        return true;
      }

      if (ajaxData.error) {
        var title = ajaxData.error.type || ajaxData.error.title;

        var $overlayHtml = PortOneFive.alert(
          ajaxData.error.traceHtml || ajaxData.error.message,
          title
        );

        $overlayHtml.find('style').remove();
        $overlayHtml.addClass('large').addClass('exception');
        $overlayHtml.find('.title').css('text-transform', 'none');

        return ajaxData.error || true;
      }
      else {
        return false;
      }
    },

    on: function () {
      this.$listener.on.apply(this, arguments);
    },

    bind: function () {
      this.$listener.on.apply(this, arguments);
    },

    done: function () {
      this.jqXHR.done.apply(this, arguments);
    },

    handleHtmlSections: function (ajaxData) {

      if (typeof ajaxData.response == 'undefined' || typeof ajaxData.response.sections == 'undefined') {
        return;
      }

      $.each(ajaxData.response.sections, function (sectionName, contentHtml) {
        var $contentHtml = $($.parseHTML(contentHtml, document, true)).portOneFiveActivate();
        $('#' + sectionName).html($contentHtml);
      });
    },

    handleMetaData: function (ajaxData) {
      if (this.options.preventMetaHandling) {
        return;
      }

      var eDataSend = $.Event('AjaxBeforeMetaHandling');

      eDataSend.ajaxData = ajaxData;
      eDataSend.preventMetaHandling = false;

      console.group('Event: %s', eDataSend.type);
      this.$listener.trigger(eDataSend);
      console.groupEnd();

      if (eDataSend.preventMetaHandling || eDataSend.isDefaultPrevented()) {
        return false;
      }

      if (typeof ajaxData.redirect != 'undefined') {
        if (typeof ajaxData.messages == 'undefined') {
          return this.redirect(ajaxData.redirect.target);
        }
        else {
          return this.handleMessages(
            ajaxData, $.context(
              function () {
                return this.redirect(ajaxData.redirect.target);
              }, this
            )
          );
        }
      }

      this.handleMessages(ajaxData);

      this.handleHtmlSections(ajaxData);
    },

    handleMessages: function (ajaxData, onClose) {
      if (typeof ajaxData.messages == 'undefined' || $.isEmptyObject(ajaxData.messages)) {
        return;
      }

      var eDataSend = new $.Event('AjaxBeforeMessages');
      eDataSend.messages = ajaxData.messages;
      eDataSend.preventMessages = false;
      eDataSend.afterMessage = null;

      console.group('Event: %s', eDataSend.type);
      this.$listener.trigger(eDataSend);
      console.groupEnd();

      if (eDataSend.isDefaultPrevented() || eDataSend.preventMessages) {
        return false;
      }

      var callback;
      if ($.isFunction(onClose)) {
        var tryTimer, $stackAlerts = $('#StackAlerts');

        tryTimer = setInterval(
          function () {
            if (!$stackAlerts.is(':visible') && $('[data-reveal]:visible').length == 0) {
              clearInterval(tryTimer);
              onClose();
            }
          }, 300
        );
      }

      if (!$.isEmptyObject(ajaxData.messages)) {
        PortOneFive.handleMessages(ajaxData.messages);
      }
    },

    /**
     * Redirect the browser to redirectTarget if it is specified
     *
     * @param {string} redirectTarget
     *
     * @return boolean
     */
    redirect: function (redirectTarget) {
      if (!this.options.preventRedirects || !parseInt(PortOneFive._enableOverlays)) {
        var eDataSend = new $.Event('AjaxBeforeRedirect');
        eDataSend.redirectTarget = redirectTarget;

        console.group('Event: %s', eDataSend.type);
        this.$listener.trigger(eDataSend);
        console.groupEnd();

        if (!eDataSend.isDefaultPrevented() && eDataSend.redirectTarget) {
          PortOneFive.redirect(redirectTarget);
          return true;
        }
      }

      return false;
    }

  };

}(jQuery, this, document);
