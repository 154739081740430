/** @param {jQuery} $ jQuery Object */
!function ($, window, document, _undefined) {
  PortOneFive.NodeGrid = function ($nodeGrid) {
    this.__construct($nodeGrid)
  };

  PortOneFive.NodeGrid.prototype = {

    __construct: function ($nodeGrid) {
      //$nodeGrid.packery(
      //    {
      //        itemSelector: ".box",
      //        columnWidth: '.grid-sizer',
      //        gutter: 8
      //    }
      //);

      if (!$('body').hasClass('admin')) {
        return;
      }

      $nodeGrid.sortable(
        {
          items: '> .Sortable',
          forcePlaceholderSize: true,
          placeholder: "portlet-placeholder ui-corner-all"
        }
      );
    }

  };

  PortOneFive.PJAXLink = function ($link) {
    $link.on('click', function (e) {
      const $target = $(e.target);

      if (($target.is('a') || $target.parent().is('a')) && $target[0] !== $link[0] && $target.parent()[0] !== $link[0]) {
        return;
      }

      e.preventDefault();
      const href = $link.attr('href') || $link.data('pjax') || $link.data('href');

      PortOneFive.PJAXLoader(href).done(function (ajaxData) {
        window.history.pushState({}, ajaxData.title, href);
      });

      return false;
    });
  };

  PortOneFive.PJAXLoader = function (url) {
    const request = new PortOneFive.Ajax(url);
    return request.get().done(function (ajaxData) {
      $('#title').text(ajaxData.title);
      $('#pjax-content').html($.parseHTML(ajaxData.response.html)).portOneFiveActivate();
    });
  };

  $(window).on('popstate', function (event) {
    PortOneFive.PJAXLoader(window.location.href);
  });

  PortOneFive.register('[data-pjax]', 'PortOneFive.PJAXLink');
  PortOneFive.register('.NodeGrid', 'PortOneFive.NodeGrid');

}(jQuery, this, document);
