/** @param {jQuery} $ jQuery Object */
!function ($, window, document, _undefined)
{
    PortOneFive.DateRangePicker = function ($dateRangePicker)
    {
        var options = {},
            $startDateInput = $($dateRangePicker.data('startDate')),
            $endDateInput = $($dateRangePicker.data('startDate')),
            startDate = moment($($dateRangePicker.data('startDate')).val()),
            endDate = moment($($dateRangePicker.data('endDate')).val());

        if (startDate.isValid())
        {
            options.startDate = startDate;
        }

        if (endDate.isValid())
        {
            options.endDate = endDate;
        }

        if (endDate.isValid() && startDate.isValid())
        {
            $dateRangePicker.val(options.startDate.format('MM/DD/YYYY') + ' - ' + options.endDate.format('MM/DD/YYYY'));
        }

        $dateRangePicker.daterangepicker(options);

        $dateRangePicker.on(
            'apply.daterangepicker', function (e, picker)
            {
                $startDateInput.val(picker.startDate.format('YYYY-MM-DD'));
                $endDateInput.val(picker.endDate.format('YYYY-MM-DD'));
            }
        );

        console.log($dateRangePicker);
    };

    PortOneFive.register('.DateRangePicker', 'PortOneFive.DateRangePicker');

}(jQuery, this, document);
