/** @param {jQuery} $ jQuery Object */
!function ($, window, document, _undefined)
{

    /**
     * Handler for automatic AJAX form validation and error management
     *
     * Forms to be auto-validated require the following attributes:
     *
     * * data-fieldValidatorUrl: URL of a JSON-returning validator for a single field, using _POST keys of 'name' and 'value'
     * * data-optInOut: (Optional - default = OptOut) Either OptIn or OptOut, depending on the validation mode. Fields with a class of OptIn are included in opt-in mode, while those with OptOut are excluded in opt-out mode.
     * * data-exitUrl: (Optional - no default) If defined, any form reset event will redirect to this URL.
     * * data-existingDataToken: (Optional) Specifies the primary key of the data being manipulated. If this is not present, a hidden input with class="ExistingDataToken" is searched for.
     * * data-redirect: (Optional) If set, the browser will redirect to the returned _redirectTarget from the ajaxData response after validation
     *
     * @param {jQuery} $form
     */
    PortOneFive.AjaxSubmit = function ($form)
    {
        this.__construct($form);
    };
    PortOneFive.AjaxSubmit.prototype =
    {
        __construct: function ($form)
        {
            this.$form = $form.bind(
                {
                    submit: $.context(this, 'ajaxSave'),
                    reset: $.context(this, 'formReset')
                }
            );

            this.$form.find('[type="submit"]').click($.context(this, 'setClickedSubmit'));

            this.optInMode = this.$form.data('optinout') || 'optOut';
            this.ajaxSubmit = !PortOneFive.isPositive(this.$form.data('normalsubmit'));
        },

        /**
         * Intercepts form reset events.
         * If the form specifies a data-exitUrl, the browser will navigate there before resetting the form.
         *
         * @param event e
         */
        formReset: function (e)
        {
            var exitUrl = this.$form.data('exiturl');

            if (exitUrl)
            {
                PortOneFive.redirect(exitUrl);
            }
        },

        /**
         * Fires whenever a submit button is clicked, in order to store the clicked control
         *
         * @param event e
         */
        setClickedSubmit: function (e)
        {
            this.$form.data('clickedsubmitbutton', e.target);
        },

        /**
         * Intercepts form submit events.
         * Attempts to save the form with AJAX, after cancelling any pending validation tasks.
         *
         * @param event e
         *
         * @return boolean false
         */
        ajaxSave: function (e)
        {
            if (!this.ajaxSubmit || !PortOneFive._enableAjaxSubmit)
            {
                // do normal validation
                return true;
            }

            this.$form.find('small.error').remove();
            this.$form.find('label.error').removeClass('error').removeAttr('role');

            var clickedSubmitButton = this.$form.data('clickedsubmitbutton'),
                serialized,
                $clickedSubmitButton,

                /**
                 * Event listeners for this event can:
                 *    e.preventSubmit = true; to prevent any submission
                 *    e.preventDefault(); to disable ajax sending
                 */
                eDataSend = $.Event('AjaxSubmitBeforeSubmit');

            eDataSend.formAction = this.$form.attr('action');
            eDataSend.clickedSubmitButton = clickedSubmitButton;
            eDataSend.preventSubmit = false;
            eDataSend.ajaxOptions = {};
            eDataSend.extraData = [];
            eDataSend.overrideData = false;

            this.$form.trigger(eDataSend);

            this.$form.removeData('clickedSubmitButton');

            if (eDataSend.preventSubmit)
            {
                return false;
            }
            else if (!eDataSend.isDefaultPrevented())
            {
                if (!eDataSend.overrideData)
                {
                    serialized = this.$form.serializeArray();
                    if (clickedSubmitButton)
                    {
                        $clickedSubmitButton = $(clickedSubmitButton);
                        if ($clickedSubmitButton.attr('name'))
                        {
                            serialized.push(
                                {
                                    name: $clickedSubmitButton.attr('name'),
                                    value: $clickedSubmitButton.attr('value')
                                }
                            );
                        }
                    }
                }
                else
                {
                    serialized = eDataSend.extraData;
                }

                console.log(serialized);

                this.submitPending = true;

                var request = new PortOneFive.Ajax(eDataSend.formAction, eDataSend.ajaxOptions);

                request.setMethod($(this.$form.attr('method')))
                    .post(serialized)
                    .done($.context(this, 'ajaxSaveResponse'))
                    .fail($.context(this, 'ajaxErrorResponse'));

                e.preventDefault();
            }
        },

        handleFormRequestErrors: function (ajaxData)
        {
            var $form = this.$form;

            $.each(
                ajaxData,
                function (fieldName, errorMessage)
                {
                    fieldName = fieldName.replace(/\.([^\.]+)/g, '[$1]');

                    console.log(fieldName);

                    var $input = $form.find('[name="' + fieldName + '"]'),
                        $label = $('label[for="' + fieldName + '"]');

                    if (!$input.length)
                    {
                        return;
                    }

                    $label.addClass('error').attr('role', 'alert');

                    $('<small class="error">' + errorMessage + '</small>').insertAfter(
                        $input.attr('type') == 'radio'
                            ? $label
                            : $input
                    );
                }
            );

        },

        ajaxErrorResponse: function (xhr, textStatus, errorThrown)
        {
            var eError = $.Event('AjaxSubmitError');
            eError.ajaxData = ajaxData;
            eError.textStatus = textStatus;

            console.group('Event: %s', eError.type);
            this.$form.trigger(eError);
            console.groupEnd();

            if (eError.isDefaultPrevented())
            {
                return false;
            }

            try
            {
                var ajaxData = $.parseJSON(xhr.responseText);
            }
            catch (e)
            {
                PortOneFive.alert('Invalid JSON');
                console.warn('Invalid JSON returned.');
            }

            switch (xhr.status)
            {
                case 422 :
                    this.handleFormRequestErrors(ajaxData);
                    break;
            }

            if (ajaxData.response && ajaxData.response.html)
            {
                this.$error = PortOneFive.createOverlay(null, this.prepareError(ajaxData.response.html)).open();
            }
        },

        /**
         * Handles the AJAX response from ajaxSave().
         *
         * @param ajaxData
         * @param textStatus
         * @return
         */
        ajaxSaveResponse: function (ajaxData, textStatus)
        {
            this.submitPending = false;

            if (!ajaxData)
            {
                console.warn('No ajax data returned.');
                return false;
            }

            var eDataRecv,
                eComplete,
                $trigger;

            eDataRecv = $.Event('AjaxSubmitDataReceived');
            eDataRecv.ajaxData = ajaxData;
            eDataRecv.textStatus = textStatus;
            eDataRecv.validationError = [];

            console.group('Event: %s', eDataRecv.type);
            this.$form.trigger(eDataRecv);
            console.groupEnd();

            if (eDataRecv.isDefaultPrevented())
            {
                return false;
            }

            eComplete = $.Event('AjaxSubmitComplete');

            eComplete.ajaxData = ajaxData;
            eComplete.textStatus = textStatus;
            eComplete.$form = this.$form;

            console.group('Event: %s', eComplete.type);
            this.$form.trigger(eComplete);
            console.groupEnd();

            if (eComplete.isDefaultPrevented())
            {
                return false;
            }

            // if the form is in an overlay, close it
            if (this.$form.parents('[data-reveal]').length)
            {
                this.$form.parents('[data-reveal]').data('overlay').close();

                if (ajaxData.linkPhrase)
                {
                    $trigger = this.$form.parents('.reveal-modal').data('overlay').getTrigger();
                    $trigger.portOneFiveFadeOut(
                        PortOneFive.speed.fast, function ()
                        {
                            if (ajaxData.linkUrl && $trigger.is('a'))
                            {
                                $trigger.attr('href', ajaxData.linkUrl);
                            }

                            $trigger.text(ajaxData.linkPhrase).portOneFiveFadeIn(PortOneFive.speed.fast);
                        }
                    );
                }
            }

            if (this.hasResponseHtml(ajaxData))
            {
                $(ajaxData.response.html).portOneFiveInsert('replaceAll', $('[data-section="html"]'));
            }
        },

        hasResponseHtml: function (ajaxData)
        {
            return typeof ajaxData.response != 'undefined' && typeof ajaxData.response.html != 'undefined';
        },

        /**
         * Redirect the browser to redirectTarget if it is specified
         *
         * @param string redirectTarget
         *
         * @return boolean
         */
        redirect: function (redirectTarget)
        {
            if (PortOneFive.isPositive(this.$form.data('redirect')) || !parseInt(PortOneFive._enableOverlays))
            {
                var $AjaxSubmitRedirect = new $.Event('AjaxSubmitRedirect');
                $AjaxSubmitRedirect.redirectTarget = redirectTarget;

                this.$form.trigger($AjaxSubmitRedirect);

                if (!$AjaxSubmitRedirect.isDefaultPrevented() && $AjaxSubmitRedirect.redirectTarget)
                {
                    PortOneFive.redirect(redirectTarget);
                    return true;
                }
            }

            return false;
        }
    };

    // *********************************************************************

    /**
     * Handler for individual fields in an AutoValidator form.
     * Manages individual field validation and inline error display.
     *
     * @param jQuery input [text-type]
     */
    PortOneFive.AutoValidatorControl = function ($input)
    {
        this.__construct($input);
    };
    PortOneFive.AutoValidatorControl.prototype =
    {
        __construct: function ($input)
        {
            this.$form = $input.closest('form.AutoValidator').bind(
                {
                    AjaxSubmitDataReceived: $.context(this, 'handleFormValidation')
                }
            );

            this.$input = $input.bind(
                {
                    change: $.context(this, 'change'),
                    AjaxSubmitError: $.context(this, 'showError'),
                    AjaxSubmitPass: $.context(this, 'hideError')
                }
            );

            this.name = $input.data('validatorname') || $input.attr('name');
            this.autoValidate = $input.hasClass('NoAutoValidate') ? false : true;
        },

        /**
         * When the value of a field changes, initiate validation
         *
         * @param event e
         */
        change: function (e)
        {
        },

        /**
         * Fire a validation AJAX request
         */
        validate: function ()
        {

        },

        /**
         * Handle the data returned from an AJAX validation request fired in validate().
         * Fires 'AjaxSubmitPass' or 'AjaxSubmitError' for the $input according to the validation state.
         *
         * @param object ajaxData
         * @param string textStatus
         *
         * @return boolean
         */
        handleValidation: function (ajaxData, textStatus)
        {
            if (ajaxData && ajaxData.formRequestErrors && ajaxData.formRequestErrors.hasOwnProperty(this.name))
            {
                this.$input.trigger(
                    {
                        type: 'AjaxSubmitError',
                        errorMessage: ajaxData.formRequestErrors[this.name]
                    }
                );
                return false;
            }
            else
            {
                this.$input.trigger('AjaxSubmitPass');
                return true;
            }
        },

        /**
         * Shows an inline error message, text contained within a .errorMessage property of the event passed
         *
         * @param event e
         */
        showError: function (e)
        {
            console.warn('%s: %s', this.name, e.errorMessage);

            var error = this.fetchError(e.errorMessage).css('display', 'inline-block');
            this.positionError(error);
        },

        /**
         * Hides any inline error message shown with this input
         */
        hideError: function ()
        {
            console.info('%s: Okay', this.name);

            if (this.$error)
            {
                this.fetchError()
                    .hide();
            }
        },

        /**
         * Fetches or creates (as necessary) the error HTML object for this field
         *
         * @param string Error message
         *
         * @return jQuery this.$error
         */
        fetchError: function (message)
        {
            if (!this.$error)
            {
                this.$error =
                $(
                    '<label for="'
                    + this.$input.attr('id')
                    + '" class="formValidationInlineError">WHoops</label>'
                ).insertAfter(this.$input);
            }

            if (message)
            {
                this.$error.html(message).portOneFiveActivate();
            }

            return this.$error;
        },

        /**
         * Returns an object containing top and left properties, used to position the inline error message
         */
        positionError: function ($error)
        {
            $error.removeClass('inlineError');

            var coords = this.$input.coords('outer', 'position'),
                screenCoords = this.$input.coords('outer'),
                $window = $(window),
                outerWidth = $error.outerWidth(),
                absolute,
                position = {top: coords.top};

            if (!screenCoords.width || !screenCoords.height)
            {
                absolute = false;
            }
            else
            {
                if (PortOneFive.isRTL())
                {
                    position.left = coords.left - outerWidth - 10;
                    absolute = (screenCoords.left - outerWidth - 10 > 0);
                }
                else
                {
                    var screenLeft = screenCoords.left + screenCoords.width + 10;

                    absolute = screenLeft + outerWidth < ($window.width() + $window.scrollLeft());
                    position.left = coords.left + coords.width + 10;
                }
            }

            if (absolute)
            {
                $error.css(position);
            }
            else
            {
                $error.addClass('inlineError');
            }
        },

        /**
         * Handles validation for this field passed down from a submission of the whole AutoValidator
         * form, and passes the relevant data into the handler for this field specifically.
         *
         * @param event e
         */
        handleFormValidation: function (e)
        {
            if (!this.handleValidation(e.ajaxData, e.textStatus))
            {
                e.validationError.push(this.name);
            }
        }
    };

    // *********************************************************************

    /**
     * Checks a form field to see if it is part of an AutoValidator form,
     * and if so, whether or not it is subject to autovalidation.
     *
     * @param object Form control to be tested
     *
     * @return boolean
     */
    PortOneFive.isAutoValidatorField = function (ctrl)
    {
        var AutoValidator, $ctrl, $form = $(ctrl.form);

        if (!$form.hasClass('AutoValidator'))
        {
            return false;
        }

        AutoValidator = $form.data('PortOneFive.AutoValidator');

        if (AutoValidator)
        {
            $ctrl = $(ctrl);

            switch (AutoValidator.optInMode)
            {
                case 'OptIn':
                {
                    return ($ctrl.hasClass('OptIn') || $ctrl.closest('.ctrlUnit').hasClass('OptIn'));
                }
                default:
                {
                    return (!$ctrl.hasClass('OptOut') && !$ctrl.closest('.ctrlUnit').hasClass('OptOut'));
                }
            }
        }

        return false;
    };

    PortOneFive.register('form.AjaxSubmit', 'PortOneFive.AjaxSubmit');

}(jQuery, this, document);
