/**
 * Create the PortOneFive namespace
 */
var PortOneFive = {};

if (jQuery === undefined) {
    jQuery = $ = {};
}

/** @param {jQuery} $ jQuery Object */
!function ($, window, document, _undefined) {
    var isTouchBrowser = (
        function () {
            var _isTouchBrowserVal;

            try {
                _isTouchBrowserVal = !!(
                    'ontouchstart' in window
                );
            }
            catch (e) {
                _isTouchBrowserVal = !!(
                    navigator.userAgent.indexOf('webOS') != -1
                );
            }

            return function () {
                return _isTouchBrowserVal;
            };
        }
    )();

    var classes = ['hasJs'];
    classes.push(isTouchBrowser() ? 'Touch' : 'NoTouch');

    var div = document.createElement('div');
    classes.push((
                     'draggable' in div
                 ) || (
                     'ondragstart' in div && 'ondrop' in div
                 ) ? 'HasDragDrop' : 'NoDragDrop');

    var $html = $('html');
    $html.addClass(classes.join(' ')).removeClass('NoJs');

    /**
     * Fix IE abbr handling
     */
    document.createElement('abbr');

    /**
     * Detect mobile webkit
     */
    if (/webkit.*mobile/i.test(navigator.userAgent)) {
        PortOneFive._isWebkitMobile = true;
    }

    // preserve original jQuery Tools .overlay()
    //jQuery.fn._jQueryToolsOverlay = jQuery.fn.overlay;

    /**
     * Extend jQuery core
     */
    jQuery.extend(
        true, {

            /**
             * Sets the context of 'this' within a called function.
             * Takes identical parameters to $.proxy, but does not
             * enforce the one-element-one-method merging that $.proxy
             * does, allowing multiple objects of the same type to
             * bind to a single element's events (for example).
             *
             * @param {function|object} fn Function to be called | Context for 'this', method is a property of fn
             * @param {function|string} context Context for 'this' | Name of method within fn to be called
             *
             * @return function
             */
            context: function (fn, context) {
                if (typeof context == 'string') {
                    var _context = fn;
                    fn = fn[context];
                    context = _context;
                }

                return function () {
                    return fn.apply(context, arguments);
                };
            },

            /**
             * Sets a cookie.
             *
             * @param name
             * @param value
             * @param expires
             * @return mixed cookie value
             */
            setCookie: function (name, value, expires) {
                console.log('Set cookie %s=%s', name, value);

                var cookieValue = PortOneFive._cookieConfig.prefix + name + '=' + encodeURIComponent(value);

                cookieValue += expires === undefined ? '' : ';expires=' + expires.toGMTString();
                cookieValue += PortOneFive._cookieConfig.path ? ';path=' + PortOneFive._cookieConfig.path : '';
                cookieValue += PortOneFive._cookieConfig.domain ? ';domain=' + PortOneFive._cookieConfig.domain : '';

                document.cookie = cookieValue;

                return value;
            },

            /**
             * Fetches the value of named cookie.
             *
             * @param name
             * @return string Cookie value
             */
            getCookie: function (name) {
                var expr, cookie;

                expr = new RegExp('(^| )' + PortOneFive._cookieConfig.prefix + name + '=([^;]+)(;|$)');
                cookie = expr.exec(document.cookie);

                return (
                           cookie
                       ) ? decodeURIComponent(cookie[2]) : null;
            },

            /**
             * Deletes a cookie.
             *
             * @param name
             * @return null
             */
            deleteCookie: function (name) {
                console.info('Delete cookie %s', name);

                var cookieContents =
                    PortOneFive._cookieConfig.prefix + name + '=' + (
                        PortOneFive._cookieConfig.path ? '; path='
                    + PortOneFive._cookieConfig.path : ''
                    );

                cookieContents +=
                    (
                        PortOneFive._cookieConfig.domain ? '; domain=' + PortOneFive._cookieConfig.domain : ''
                    )
                    + '; expires=Thu, 01-Jan-70 00:00:01 GMT';

                document.cookie = cookieContents;

                return null;
            },

            detectIeVersion: function () {
                var ua = window.navigator.userAgent;
                var msie = ua.indexOf('MSIE ');
                var trident = ua.indexOf('Trident/');

                if (msie > 0) {
                    // IE 10 or older => return version number
                    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
                }

                if (trident > 0) {
                    // IE 11 (or newer) => return version number
                    var rv = ua.indexOf('rv:');
                    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
                }

                // other browser
                return false;
            }
        }
    );

    /**
     * Extend jQuery functions
     */
    jQuery.fn.extend(
        {
            /**
             * Wrapper for PortOneFive.activate, for 'this' element
             *
             * @return jQuery
             */
            portOneFiveActivate: function () {
                return PortOneFive.activate(this);
            },

            /**
             * Retuns .data(key) for this element, or the default value if there is no data
             *
             * @param key
             * @param defaultValue
             *
             * @return mixed
             */
            dataOrDefault: function (key, defaultValue) {
                var value = this.data(key);

                if (value === undefined) {
                    return defaultValue;
                }

                return value;
            },

            /**
             * Get the 'name' attribute of an element, or if it exists, the value of 'data-field-name'
             *
             * @return string
             */
            fieldName: function () {
                return this.data('fieldName') || this.attr('name');
            },

            /**
             * Like .val() but also trims trailing whitespace
             */
            strval: function () {
                return String(this.val()).replace(/\s+$/g, '');
            },

            /**
             * Get the value that would be submitted with 'this' element's name on form submit
             *
             * @return string
             */
            fieldValue: function () {
                switch (this.attr('type')) {
                    case 'checkbox':
                    {
                        return $('input:checkbox[name="' + this.fieldName() + '"]:checked', this.context.form).val();
                    }

                    case 'radio':
                    {
                        return $('input:radio[name="' + this.fieldName() + '"]:checked', this.context.form).val();
                    }

                    default:
                    {
                        return this.val();
                    }
                }
            },

            /**
             * Wrapper functions for commonly-used animation effects
             */
            portOneFiveFadeIn: function (speed, callback) {
                return this.fadeIn(
                    speed, function () {
                        //$(this).ieOpacityFix(callback);

                        if ($.isFunction(callback)) {
                            callback.apply(this);
                        }
                    }
                );
            },

            portOneFiveFadeOut: function (speed, callback) {
                return this.fadeOut(speed, callback);
            },

            portOneFiveShow: function (speed, callback) {
                return this.show(
                    speed, function () {
                        //$(this).ieOpacityFix(callback);

                        if ($.isFunction(callback)) {
                            callback.apply(this);
                        }
                    }
                );
            },

            portOneFiveHide: function (speed, callback) {
                return this.hide(speed, callback);
            },

            portOneFiveSlideDown: function (speed, callback) {
                return this.slideDown(
                    speed, function () {
                        //$(this).ieOpacityFix(callback);

                        if ($.isFunction(callback)) {
                            callback.apply(this);
                        }
                    }
                );
            },

            portOneFiveSlideUp: function (speed, callback) {
                return this.slideUp(speed, callback);
            },

            /**
             * Animates an element opening a space for itself, then fading into that space
             *
             * @param {int|string} [fadeSpeed] Speed of fade-in
             * @param {function} [callback] Callback function on completion
             *
             * @return jQuery
             */
            portOneFiveFadeLeft: function (fadeSpeed, callback) {
                //this.filter(':hidden').portOneFiveHide().css('opacity', 0);

                fadeSpeed = fadeSpeed || PortOneFive.speed.normal;

                return this.show(
                    'slide',
                    {
                        direction: 'right',
                        queue: false,
                        easing: 'easeOutSine'
                    },
                    fadeSpeed,
                    callback
                );
            },

            /**
             * Animates an element fading out then closing the gap left behind
             *
             * @param {int|string} [fadeSpeed] Speed of fade-out - if this is zero, there will be no animation at all
             * @param {function} [callback] Callback function on completion
             * @param {int|string} [slideSpeed] Slide speed - ignored if fadeSpeed is zero
             * @param {string} [easingMethod] Easing method
             *
             * @return jQuery
             */
            portOneFiveFadeRight: function (fadeSpeed, callback, slideSpeed, easingMethod) {
                fadeSpeed =
                    typeof fadeSpeed == 'undefined' || fadeSpeed === null ? PortOneFive.speed.normal : fadeSpeed;
                slideSpeed = typeof slideSpeed == 'undefined' || slideSpeed === null ? fadeSpeed : slideSpeed;

                return this.hide('slide', {direction: 'right'});
            },

            /**
             * Animates an element opening a space for itself, then fading into that space
             *
             * @param {int|string} fadeSpeed Speed of fade-in
             * @param {function} callback Callback function on completion
             *
             * @return jQuery
             */
            portOneFiveFadeDown: function (fadeSpeed, callback) {
                this.filter(':hidden').portOneFiveHide().css('opacity', 0);

                fadeSpeed = fadeSpeed || PortOneFive.speed.normal;

                return this.portOneFiveSlideDown(PortOneFive.speed.fast).animate(
                    {opacity: 1}, fadeSpeed, callback
                );
            },

            /**
             * Animates an element fading out then closing the gap left behind
             *
             * @param {int|string} fadeSpeed Speed of fade-out - if this is zero, there will be no animation at all
             * @param {function} callback Callback function on completion
             * @param {int|string} slideSpeed Slide speed - ignored if fadeSpeed is zero
             * @param {string} easingMethod Easing method
             *
             * @return jQuery
             */
            portOneFiveFadeUp: function (fadeSpeed, callback, slideSpeed, easingMethod) {
                fadeSpeed =
                    typeof fadeSpeed == 'undefined' || fadeSpeed === null ? PortOneFive.speed.normal : fadeSpeed;
                slideSpeed = typeof slideSpeed == 'undefined' || slideSpeed === null ? fadeSpeed : slideSpeed;

                return this.slideUp(
                    {
                        duration: Math.max(fadeSpeed, slideSpeed),
                        easing: easingMethod || 'swing',
                        complete: callback,
                        queue: false
                    }
                ).animate(
                    {
                        opacity: 0,
                        queue: false
                    }, fadeSpeed
                );
            },

            /**
             * Inserts and activates content into the DOM, using portOneFiveFadeDown to animate the insertion
             *
             * @param {string} insertMethod jQuery method with which to insert the content
             * @param {string} insertReference Selector for the previous parameter
             * @param {string} animateMethod jQuery method with which to animate the showing of the content
             * @param {string|int} animateSpeed Speed at which to run the animation
             * @param {function} callback Callback for when the animation is complete
             *
             * @return jQuery
             */
            portOneFiveInsert: function (insertMethod, insertReference, animateMethod, animateSpeed, callback) {
                if (insertMethod == 'replaceAll') {
                    $(insertReference).portOneFiveFadeUp(animateSpeed);
                }

                this.addClass('__PortOneFiveActivator').css('display', 'none')
                    [insertMethod || 'appendTo'](insertReference).portOneFiveActivate()
                    [animateMethod || 'portOneFiveFadeDown'](animateSpeed, callback);

                return this;
            },

            /**
             * Removes an element from the DOM, animating its removal with portOneFiveFadeUp
             * All parameters are optional.
             *
             *  @param {string} animateMethod animation method
             *  @param {function} callback callback function
             *  @param {int} slideSpeed Sliding speed
             *  @param {string} easingMethod Easing method
             *
             * @return jQuery
             */
            portOneFiveRemove: function (animateMethod, callback, slideSpeed, easingMethod) {
                return this[animateMethod || 'portOneFiveFadeUp'](
                    PortOneFive.speed.normal, function () {
                        $(this).empty().remove();

                        if ($.isFunction(callback)) {
                            callback();
                        }
                    }, slideSpeed, easingMethod
                );
            },

            /**
             * Prepares an element for portOneFiveSlideIn() / portOneFiveSlideOut()
             *
             * @param {boolean} getHeight If true, return the height of the wrapper
             *
             * @return jQuery|integer
             */
            _portOneFiveSlideWrapper: function (getHeight) {
                if (!this.data('slidewrapper')) {
                    this.data(
                        'slidewrapper',
                        this.wrap('<div class="_swOuter"><div class="_swInner" /></div>').closest('div._swOuter').css(
                            'overflow', 'hidden'
                        )
                    );
                }

                if (getHeight) {
                    try {
                        return this.data('slidewrapper').height();
                    }
                    catch (e) {
                        return 0;
                    }
                }

                return this.data('slidewrapper');
            },

            /**
             * Slides content in (down), with content glued to lower edge, drawer-like
             *
             * @param {int} duration
             * @param {string} easing
             * @param {function} [callback]
             *
             * @return jQuery
             */
            portOneFiveSlideIn: function (duration, easing, callback) {
                var $wrap = this._portOneFiveSlideWrapper().css('height', 'auto'), height = 0;

                $wrap.find('div._swInner').css('margin', 'auto');
                height = this.show(0).outerHeight();

                $wrap.css('height', 0).animate(
                    {height: height}, duration, easing, function () {
                        $wrap.css('height', '');
                    }
                ).find('div._swInner').css('marginTop', height * -1).animate(
                    {marginTop: 0}, duration, easing, callback
                );

                return this;
            },

            /**
             * Slides content out (up), reversing portOneFiveSlideIn()
             *
             * @param {int} [duration]
             * @param {string} [easing]
             * @param {function} [callback]
             *
             * @return jQuery
             */
            portOneFiveSlideOut: function (duration, easing, callback) {
                var height = this.outerHeight();

                this._portOneFiveSlideWrapper().animate(
                    {height: 0}, duration, easing
                ).find('div._swInner').animate({marginTop: height * -1}, duration, easing, callback);

                return this;
            },

            /**
             * Flashes ("highlights") content to indicate a changed/new status
             *
             * @param {string} color
             * @param {int} [duration]
             * @param {string} [easing]
             * @param {function} [callback]
             *
             * @return jQuery
             */
            portOneFiveHighlightNew: function (color, duration, easing, callback) {
                return $(this).addClass('new');

                var highlightBg = color || '#f08a24';
                var animationDuration = duration || PortOneFive.speed.normal;
                var originalBackground = this.css('background-color');
                var animationEasing = easing || 'swing';

                if (!originalBackground || originalBackground == highlightBg) {
                    originalBackground = "#FFFFFF";
                }

                this
                    .css('backgroundColor', highlightBg)
                    .animate(
                        {backgroundColor: originalBackground}, animationDuration, null, function () {
                            $(this).css('backgroundColor', originalBackground);

                            if ($.isFunction(callback)) {
                                callback.apply(this);
                            }
                        }
                    );

                return this;
            },

            ///**
            // * Workaround for IE's font-anti-aliasing bug when dealing with opacity
            // *
            // * @param {function} callback Callback
            // */
            //ieOpacityFix: function (callback)
            //{
            //    //ClearType Fix
            //    if (!$.support.opacity)
            //    {
            //        this.css('filter', '');
            //        this.attr('style', this.attr('style').replace(/filter:\s*;/i, ''));
            //    }
            //
            //    if ($.isFunction(callback))
            //    {
            //        callback.apply(this);
            //    }
            //
            //    return this;
            //},

            overlay: function (conf) {

                var defaultConf = {
                    show: false,
                    oneInstance: true,
                    backdrop: true,
                    target: null // target element to be overlayed. by default taken from [rel]
                };

                // already constructed --> return API
                var el = this.data('overlay');
                if (el) {
                    return el;
                }

                var onOpened;

                if ($.isFunction(conf)) {
                    onOpened = conf;
                    conf = {};
                }

                conf = $.extend(true, {}, defaultConf, conf);

                this.each(
                    function () {
                        el = new PortOneFive.Overlay($(this), conf);

                        if ($.isFunction(onOpened)) {
                            el.opened(onOpened);
                        }

                        $(this).data('overlay', el);

                        el.getOverlay().appendTo('body');
                    }
                );

                return conf.api ? el : this;
            }
        }
    );

    $.extend(
        PortOneFive, {

            /**
             * Enable ajax for use regular pages
             *
             * @var boolean
             */
            _enableAjaxTriggers: true,

            /**
             * Defines whether or not an AJAX request is known to be in progress
             *
             *  @var boolean
             */
            _ajaxProgress: false,

            /**
             * Defines whether or not to show the "ajax loading" inidicator during ajax request
             *
             *  @var boolean
             */
            _showAjaxIndicator: true,

            /**
             * Defines a variable that can be overridden to force/control the base HREF
             * used to canonicalize AJAX requests
             *
             * @var string
             */
            ajaxBaseHref: '',

            /**
             * Contains the URLs of all externally loaded resources from scriptLoader
             *
             * @var object
             */
            _loadedScripts: {},

            /**
             * Configuration for cookies
             *
             * @var object
             */
            _cookieConfig: {
                path: '/',
                domain: '',
                'prefix': 'portOneFive_'
            },

            /**
             * Flag showing whether or not the browser window has focus. On load, assume true.
             *
             * @var boolean
             */
            _hasFocus: true,

            /**
             * @var object Information about the PortOneFive visitor. Usually contains user_id.
             */
            visitor: {},

            /**
             * @var integer Time the page was loaded.
             */
            _pageLoadTime: (
                               new Date()
                           ).getTime() / 1000,

            /**
             * JS version key, to force refreshes when needed
             *
             * @var string
             */
            _jsVersion: '',

            /**
             * CSRF Token
             *
             * @var string
             */
            _token: '',

            /**
             * Speeds for animation
             *
             * @var object
             */
            speed: {
                xxfast: 50,
                xfast: 100,
                fast: 200,
                normal: 300,
                slow: 450
            },

            /**
             * Multiplier for animation speeds
             *
             * @var float
             */
            _animationSpeedMultiplier: 1,

            /**
             * Enables AJAX submission via AjaxSubmit. Doesn't change things other than
             * that. Useful to disable for debugging.
             *
             * @var boolean
             */
            _enableAjaxSubmit: true,

            /**
             * @var object Phrases
             */
            phrases: {},

            /**
             * Binds all registered functions to elements within the DOM
             */
            init: function () {
                var dStart = new Date(),
                    portOneFiveFocus = function () {
                        PortOneFive._hasFocus = true;
                        $(document).triggerHandler('PortOneFiveWindowFocus');
                    },
                    portOneFiveBlur = function () {
                        PortOneFive._hasFocus = false;
                        $(document).triggerHandler('PortOneFiveWindowBlur');
                    };

                if ($.detectIeVersion()) {
                    $(document).bind(
                        {
                            focusin: portOneFiveFocus,
                            focusout: portOneFiveBlur
                        }
                    );
                }
                else {
                    $(window).bind(
                        {
                            focus: portOneFiveFocus,
                            blur: portOneFiveBlur
                        }
                    );
                }

                // init ajax progress indicators
                // PortOneFive.AjaxProgress();

                // Activate all registered controls
                PortOneFive.activate(document);

                // Autofocus for non-supporting browsers
                if (!(
                        'autofocus' in document.createElement('input')
                    )) {
                    $('input[autofocus], textarea[autofocus], select[autofocus]').first().focus();
                }

                PortOneFive.OverlayManager.init();

                if (typeof PortOneFive.messages != 'undefined') {
                    PortOneFive.handleMessages(PortOneFive.messages);
                }

                console.info('PortOneFive.init() %dms. jQuery %s', new Date() - dStart, $().jquery);
            },

            /**
             * Asynchronously load the specified JavaScript, with an optional callback on completion.
             *
             * @param {string} src Script source
             * @param {object} callback Callback function
             * @param {string} innerHTML innerHtml for the script tags
             */
            loadJs: function (src, callback, innerHTML) {
                try {
                    var script = document.createElement('script');
                    script.async = true;
                    if (innerHTML) {
                        try {
                            script.innerHTML = innerHTML;
                        }
                        catch (e2) {
                        }
                    }
                    var f = function () {
                        if (callback) {
                            callback();
                            callback = null;
                        }
                    };
                    script.onload = f;
                    script.onreadystatechange = function () {
                        if (script.readyState === 'loaded') {
                            f();
                        }
                    };
                    script.src = src;
                    document.getElementsByTagName('head')[0].appendChild(script);
                }
                catch (e) {
                }
            },

            /**
             * Binds a function to elements to fire on a custom event
             *
             * @param {string} selector jQuery selector - to get the elements to be bound
             * @param {string} fn Function to fire
             * @param {string} [event] Custom event name (if empty, assume 'PortOneFiveActivateHtml')
             */
            register: function (selector, fn, event) {
                if (typeof fn == 'string') {
                    var className = fn;
                    fn = function (i) {
                        PortOneFive.create(className, this);
                    };
                }

                $(document).bind(
                    event || 'PortOneFiveActivateHtml', function (e) {
                        $(e.element).find(selector).each(fn);
                    }
                );
            },

            /**
             * Creates a new object of class PortOneFive.{functionName} using
             * the specified element, unless one has already been created.
             *
             * @param {string} className Function name (property of PortOneFive)
             * @param {object} element HTML element
             *
             * @return object PortOneFive[functionName]($(element))
             */
            create: function (className, element) {
                var $element = $(element), portOneFiveObj = window, parts = className.split('.'), i;

                for (i = 0; i < parts.length; i++) {
                    portOneFiveObj = portOneFiveObj[parts[i]];
                }

                if (typeof portOneFiveObj != 'function') {
                    return console.error('%s is not a function.', className);
                }

                if (!$element.data(className)) {
                    $element.data(className, new portOneFiveObj($element));
                }

                return $element.data(className);
            },

            /**
             * Fire the initialization events and activate functions for the specified element
             *
             * @param {object} element Usually jQuery
             *
             * @return object
             */
            activate: function (element) {
                var $element = $(element);

                console.group('PortOneFive.activate(%o)', element);

                $element.trigger('PortOneFiveActivate').removeClass('__PortOneFiveActivator');
                $element.find('noscript').empty().remove();

                $(document).trigger(
                    {
                        element: element,
                        type: 'PortOneFiveActivateHtml'
                    }
                ).trigger(
                    {
                        element: element,
                        type: 'PortOneFiveActivatePopups'
                    }
                ).trigger(
                    {
                        element: element,
                        type: 'PortOneFiveActivationComplete'
                    }
                );

                $(document).foundation('reflow');

                console.groupEnd();

                return element;
            },

            /**
             * Pushes an additional parameter onto the data to be submitted via AJAX
             *
             * @param {string[]|string} data Data parameters - either from .serializeArray() or .serialize()
             * @param {string} name Name of parameter
             * @param {*} value Value of parameter
             *
             * @return array|string Data including new parameter
             */
            ajaxDataPush: function (data, name, value) {
                if (!data || typeof data == 'string') {
                    // data is empty, or a url string - &name=value
                    data = String(data);
                    data += '&' + encodeURIComponent(name) + '=' + encodeURIComponent(value);
                }
                else if (data[0] !== undefined) {
                    // data is a numerically-keyed array of name/value pairs
                    data.push(
                        {
                            name: name,
                            value: value
                        }
                    );
                }
                else {
                    // data is an object with a single set of name & value properties
                    data[name] = value;
                }

                return data;
            },

            handleMessages: function (data, callback) {
                if (!$.isEmptyObject(data)) {
                    $.each(
                        data, function (i, messageData) {
                            switch (messageData.type) {
                                case 'success' :
                                    return PortOneFive.alert(
                                        messageData.message,
                                        null,
                                        messageData.class,
                                        typeof messageData.timeOut == 'undefined' ? 2000 : messageData.timeOut,
                                        callback
                                    );

                                case 'error' :
                                    return PortOneFive.alert(
                                        messageData.message,
                                        messageData.title,
                                        messageData.class,
                                        false,
                                        callback
                                    );

                                case 'warning' :
                                case 'notify' :
                                case 'info' :
                                    return PortOneFive.stackAlert(
                                        messageData.message,
                                        messageData.class,
                                        typeof messageData.timeOut == 'undefined' ? 5000 : messageData.timeOut,
                                        callback
                                    );

                                case 'overlay' :
                                    return PortOneFive.alert(
                                        messageData.message,
                                        messageData.title,
                                        messageData.class,
                                        false,
                                        callback
                                    );

                            }

                        }
                    );
                }
            },

            /**
             * Checks that the supplied ajaxData has a key that can be used to create a jQuery object
             *
             *  @param {object} ajaxData ajaxData
             *  @param {string} templateKey key to look for (defaults to 'html')
             *
             *  @return boolean
             */
            hasTemplateHtml: function (ajaxData, templateKey) {
                templateKey = templateKey || 'html';

                if (!ajaxData.response || !ajaxData.response[templateKey]) {
                    return false;
                }

                if (typeof(
                        ajaxData.response[templateKey].search
                    ) == 'function') {
                    return (
                        ajaxData.response[templateKey].search(/\S+/) !== -1
                    );
                }

                return true;
            },

            /**
             * Present the user with a pop-up, modal message that they must confirm
             *
             * @param message
             * @param className
             * @param timeOut
             * @param onClose
             */
            alert: function (message, title, className, timeOut, onClosed) {
                message = String(message || 'Unspecified error');

                var key = message.replace(/[^a-z0-9_]/gi, '_') + parseInt(timeOut),
                    $overlayHtml;

                if (PortOneFive._OverlayCache[key] === undefined) {
                    if (timeOut) {
                        $overlayHtml = $(
                            ''
                            + '<div class="timed-message reveal-modal __PortOneFiveActivator" data-reveal>'
                            + message
                            + '<span class="close close-reveal-modal"></span>'
                            + '</div>'
                        );

                        $overlayHtml.appendTo('body').portOneFiveActivate();

                        PortOneFive._OverlayCache[key] = $overlayHtml.overlay(
                            {
                                oneInstance: false,
                                backdrop: false,
                                cache: false,
                                api: true
                            }
                        );

                        //PortOneFive._OverlayCache[key] = $overlayHtml.data('overlay');

                        PortOneFive._OverlayCache[key].opened(
                            function (e, overlay) {
                                var $alert = overlay.getOverlay();

                                $alert.addClass('show');

                                setTimeout(
                                    function () {
                                        $alert.removeClass('show');
                                        overlay.close();
                                    },
                                    timeOut
                                );
                            }
                        );

                        PortOneFive._OverlayCache[key].closed(
                            function (e, overlay) {
                                overlay.getOverlay().remove();
                            }
                        );

                        if ($.isFunction(onClosed)) {
                            PortOneFive._OverlayCache[key].closed(onClosed);
                        }
                    }
                    else {
                        $overlayHtml = $(
                            ''
                            + '<div class="reveal-modal error-overlay ' + className + '" data-reveal>'
                            + '<a class="close close-reveal-modal">&#215;</a>'
                            + '<h2 class="title ' + className + '">'
                            + (
                                title || 'The following error occurred'
                            )
                            + '</h2>'
                            + '<p class="primary-content content"></p>'
                            + '</div>'
                        );

                        $overlayHtml.find('p.primary-content').html(message);

                        $overlayHtml.appendTo('body').portOneFiveActivate();

                        PortOneFive._OverlayCache[key] = PortOneFive.createOverlay(null, $overlayHtml, {show: true});

                        PortOneFive._OverlayCache[key].opened(
                            function () {
                                var el = $('input:button.close, button.close', document.getElementById(key)).get(0);
                                if (el) {
                                    el.focus();
                                }
                            }
                        );

                        if ($.isFunction(onClosed)) {
                            PortOneFive._OverlayCache[key].closed(onClosed);
                        }
                    }

                    //$overlayHtml.data('overlay', PortOneFive._OverlayCache[key]);

                    //return PortOneFive._OverlayCache[key].getOverlay();
                }

                PortOneFive._OverlayCache[key].open();

                return PortOneFive._OverlayCache[key].getOverlay();
            },

            /**
             * Shows a mini timed alert message, much like the OS X notifier 'Growl'
             *
             * @param string message
             * @param integer timeOut Leave empty for a sticky message
             * @param jQuery onClose
             */
            stackAlert: function (message, className, timeOut, onClose) {
                var $message = $(
                        '<li><p class="stack-alert-content">'
                        + '<a class="close"></a>'
                        + '</p></li>'
                    ),

                    $container = $('#StackAlerts');

                $message.addClass(className || 'info');

                if (!$container.length) {
                    $container = $('<ul id="StackAlerts"></ul>').appendTo('body');
                }

                message = message instanceof jQuery ? message : $('<span>' + message + '</span>');

                message.appendTo($message.find('p.stack-alert-content'));

                function removeMessage() {
                    $message.portOneFiveFadeUp(PortOneFive.speed.slow, function () {
                        $(this).empty().remove();

                        if (!$container.children().length) {
                            $container.hide();

                            if (onClose) {
                                onClose();
                            }
                        }
                    });
                }

                $message
                    .hide()
                    .prependTo($container.show())
                    .portOneFiveFadeDown(PortOneFive.speed.normal, function () {
                        if (timeOut > 0) {
                            setTimeout(removeMessage, timeOut);
                        }
                    });

                $message.find('a').click(removeMessage);
            },

            redirect: function (url) {
                url = PortOneFive.canonicalizeUrl(url);

                if (url == window.location.href) {
                    window.location.reload();
                }
                else {
                    window.location = url;

                    var destParts = url.split('#'), srcParts = window.location.href.split('#');

                    if (destParts[1]) // has a hash
                    {
                        if (destParts[0] == srcParts[0]) {
                            // destination has a hash, but going to the same page
                            window.location.reload();
                        }
                    }
                }
            },

            redirectBack: function () {
                return history.back();
            },

            canonicalizeUrl: function (url, baseHref) {
                if (url.indexOf('/') == 0) {
                    return url;
                }
                else if (url.match(/^https?:|ftp:|mailto:/i)) {
                    return url;
                }
                else {
                    if (!baseHref) {
                        baseHref = PortOneFive.baseUrl();
                    }
                    if (typeof baseHref != 'string') {
                        baseHref = '';
                    }
                    return baseHref + url;
                }
            },

            _baseUrl: false,

            baseUrl: function () {
                if (PortOneFive._baseUrl === false) {
                    var b = document.createElement('a');
                    b.href = '';
                    PortOneFive._baseUrl = b.href;

                    if (!$('base').length) {
                        PortOneFive._baseUrl = PortOneFive._baseUrl.replace(/\?.*$/, '').replace(/\/[^\/]*$/, '/');
                    }
                }

                return PortOneFive._baseUrl;
            },

            /**
             * Escapes a string so it can be inserted into a RegExp without altering special characters
             *
             * @param string
             *
             * @return string
             */
            regexQuote: function (string) {
                return (
                    string + ''
                ).replace(/([\\\.\+\*\?\[\^\]\$\(\)\{\}\=\!<>\|\:])/g, "\\$1");
            },

            /**
             * Checks that the value passed is a numeric value, even if its actual type is a string
             *
             * @param {*} value Value to be checked
             *
             * @return boolean
             */
            isNumeric: function (value) {
                return (
                    !isNaN(value) && (
                                         value - 0
                                     ) == value && value.length > 0
                );
            },

            /**
             * Helper to check that an attribute value is 'positive'
             *
             * @param value Value to check
             *
             * @return boolean
             */
            isPositive: function (value) {
                switch (String(value).toLowerCase()) {
                    case 'on':
                    case 'yes':
                    case 'true':
                    case '1':
                        return true;

                    default:
                        return false;
                }
            },

            /**
             * Converts the first character of a string to uppercase.
             *
             * @param string
             *
             * @return string
             */
            ucfirst: function (string) {
                return string.charAt(0).toUpperCase() + string.substr(1);
            },

            /**
             * Determines whether or not we are working with a touch-based browser
             *
             * @return boolean
             */
            isTouchBrowser: isTouchBrowser,

            /**
             * Lazy-loads Javascript files
             */
            scriptLoader: {
                loadScript: function (url, success, failure) {
                    if (PortOneFive._loadedScripts[url] === undefined) {
                        $.ajax(
                            {
                                type: 'GET',
                                url: url,
                                cache: false,
                                dataType: 'script',
                                error: failure,
                                success: function (javascript, textStatus) {
                                    PortOneFive._loadedScripts[url] = true;
                                    success.call();
                                }
                            }
                        );
                    }
                    else {
                        success.call();
                    }
                },

                loadCss: function (css, urlTemplate, success, failure) {
                    // GURU : make load CSS work
                    var stylesheets = [], url;

                    // build a list of stylesheets we have not already loaded
                    $.each(
                        css, function (i, stylesheet) {
                            if (!PortOneFive._loadedScripts[stylesheet]) {
                                stylesheets.push(stylesheet);
                            }
                        }
                    );

                    // if there are any left, construct the URL and load them
                    if (stylesheets.length) {
                        url = urlTemplate.replace('__sentinel__', stylesheets.join(','));
                        url = PortOneFive.canonicalizeUrl(url, PortOneFive.ajaxBaseHref);

                        $.ajax(
                            {
                                type: 'GET',
                                url: url,
                                cache: false,
                                dataType: 'text',
                                error: failure,
                                success: function (cssText, textStatus) {
                                    $.each(
                                        stylesheets, function (i, stylesheet) {
                                            console.log('Loaded css %d, %s', i, stylesheet);
                                            PortOneFive._loadedScripts[stylesheet] = true;
                                        }
                                    );

                                    var baseHref = PortOneFive.baseUrl();
                                    if (baseHref) {
                                        cssText = cssText.replace(
                                            /(url\(("|')?)([^"')]+)(("|')?\))/gi,
                                            function (all, front, null1, url, back, null2) {
                                                if (!url.match(/^(https?:|\/)/i)) {
                                                    url = baseHref + url;
                                                }
                                                return front + url + back;
                                            }
                                        );
                                    }

                                    $('<style>' + cssText + '</style>').appendTo('head');

                                    success.call();
                                }
                            }
                        );
                    }
                    else {
                        success.call();
                    }
                }
            }
        }
    );

    /**
     * Shows and hides global request pending progress indicators for AJAX calls.
     *
     * Binds to the global ajaxStart and ajaxStop jQuery events.
     * Also binds to the PseudoAjaxStart and PseudoAjaxStop events,
     * see PortOneFive.AutoInlineUploader
     *
     * Initialized by PortOneFive.init()
     */
    PortOneFive.AjaxProgress = function () {
        var overlay = null,

            showIndicators = function () {
                // mini indicators
                //$('.Progress, form .submit-unit').addClass('InProgress');
                $('.AjaxProgress').addClass('active');
            },

            hideIndicators = function () {
                // mini indicators
                //$('.Progress, form .submit-unit').removeClass('InProgress');
                $('.AjaxProgress').removeClass('active');
            };

        $(document).bind(
            {
                ajaxStart: function (e) {
                    PortOneFive._AjaxProgress = true;
                    showIndicators();
                },

                ajaxStop: function (e) {
                    PortOneFive._AjaxProgress = false;
                    setTimeout(hideIndicators, 300);
                },

                PseudoAjaxStart: function (e) {
                    showIndicators();
                },

                PseudoAjaxStop: function (e) {
                    hideIndicators();
                }
            }
        );
    };

    // *********************************************************************

    PortOneFive.ResetButton = function ($resetButton) {
        if ($resetButton.closest('.reveal-modal').length == 0) {
            $resetButton.on('click', function (e) {
                history.back();
            });
        }
    };

    PortOneFive.register('input[type="reset"]', 'PortOneFive.ResetButton');

    // *********************************************************************

    var isScrolled = false;
    $(window).ready(function () {
        if (isScrolled || !window.location.hash) {
            return;
        }

        var hash = window.location.hash.replace(/[^a-zA-Z0-9_-]/g, ''), $match = hash ? $('#' + hash) : $();

        if ($match.length) {
            $match.get(0).scrollIntoView(true);
        }
    });

    PortOneFive.Foundation = {
        init: function () {
            $(document).foundation({
                reveal: {
                    animation: 'fadeAndPop',
                    animation_speed: 200,
                    multiple_opened: true,
                    root_element: 'body'

                }
            });
        }
    };

    /**
     * Use jQuery to initialize the system
     */
    $(
        function () {
            PortOneFive.Foundation.init();
            PortOneFive.init();

            if (window.location.hash) {
                // do this after the document is ready as triggering it too early
                // causes the initial hash to trigger a scroll
                $(window).one(
                    'scroll', function (e) {
                        isScrolled = true;
                    }
                );
            }

            //if (PortOneFive.messages) {
            //    PortOneFive.handleMessages(PortOneFive.messages, function () {
            //        PortOneFive.messages = [];
            //    });
            //}
        }
    );

}(jQuery, this, document);
